import { DateTime, Zone } from 'luxon';

import { ExtendedRideRequest } from '../models/ride.model';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class TimeHelperService {
  constructor() { }

  static DateFormat = 'M/d/yyyy';
  /**
   * Takes separate date fields (such as from user input) and constructs an ISO 8601 string _with_ timezone offset.
   * @param formValues an object (such as form values) containing the necessary DateTime fields
   */
  static getDateTime(date: string, time: string = '00:00', type: 'Date' | 'DateTime' | 'ISO' = 'Date'): Date | DateTime | string {
    if (!date) { return null; }
    const [hour, minute] = time ? time.split(':').map((val) => Number(val)) : [0, 0];
    const priorityTime = DateTime.fromISO(date).set({ hour, minute });
    // the "true" parameter is required to include the timezone offset
    switch (type) {
      case 'DateTime': return priorityTime;
      case 'ISO': return priorityTime.toISO({ includeOffset: true });
      default: return priorityTime.toUTC().toJSDate();
    }
  }

  static getReturnTime({ date, priorityTime }: { date: string, priorityTime: string }): string {
    if (!priorityTime) { return null; }
    const today = DateTime.now();
    if (!priorityTime) { return DateTime.now().plus({ hours: 2 }).toFormat('HH:dd'); }
    const [hour, minute] = priorityTime.split(':').map((val) => Number(val));
    const returnTime = DateTime.fromISO(date).set({ hour, minute }).plus({ hours: 2 });
    if (returnTime.toISODate() === today.toISODate()) {
      return returnTime.toFormat('HH:mm');
    }
    return DateTime.fromISO(date).set({ hour: 23, minute: 59 }).toFormat('HH:mm');
  }

  /**
   * Returns the ap-standard short date format
   * @param priorityTime an ISO DateTime string
   */
  static getDate(priorityTime: string): string {
    const date = DateTime.fromISO(priorityTime, { zone: 'utc' }).toLocal().toFormat(
      TimeHelperService.DateFormat,
    );
    return date;
  }


  /**
   * Returns a Date in the string format required by the requestForm
   * @param rideDate an ISO DateTime string
   */
  static getDateInFormFormat(rideDate: string): string {
    const date = DateTime.fromISO(rideDate, { zone: 'utc' }).toLocal().toFormat(
      'yyyy-MM-dd',
    );
    return date;
  }


  /**
   * Returns the app-standard long date format
   * @param priorityTime an ISO DateTime string
   */
  static getLongDate(priorityTime: string): string {
    const date = DateTime.fromISO(priorityTime, { zone: 'utc' }).toLocal().toFormat(
      'ccc, DD',
    );
    return date;
  }

  /**
   * Returns the 24-hour formatted time.
   * @param priorityTime an ISO DateTime string
   */
  static getTime(priorityTime: string): string {
    const time = priorityTime ? DateTime.fromISO(priorityTime, { zone: 'utc' }).toLocal().toFormat('HH:mm') : null;
    return time;
  }

  /**
 * Returns the 12-hour formatted time.
 * @param priorityTime an ISO DateTime string
 */
  static getDisplayTime(priorityTime: string): string {
    const time = priorityTime ? DateTime.fromISO(priorityTime, { zone: 'utc' }).toLocal().toFormat('h:mm a') : null;
    return time;
  }

  static getNonAdjustedDate(datetime: string): string {
    return DateTime.fromISO(datetime).toFormat(TimeHelperService.DateFormat);
  }

  /**
   * Returns the 12-hour time
   * @param priorityTime an ISO DateTime string
   */
  static getHour(priorityTime: string): string {
    return priorityTime ? DateTime.fromISO(priorityTime, { zone: 'utc' }).toLocal().toFormat('hh') : null;
  }

  /**
   * Returns the 24-hour time
   * @param priorityTime an ISO DateTime string
   */
  static get24Hour(priorityTime: string): string {
    return priorityTime ? DateTime.fromISO(priorityTime, { zone: 'utc' }).toLocal().toFormat('HH:mm') : null;
  }

  /**
   * Returns the minutes formatted in two digits
   * @param priorityTime an ISO DateTime string
   */
  static getMinute(priorityTime: string): string {
    return priorityTime ? DateTime.fromISO(priorityTime, { zone: 'utc' }).toLocal().toFormat('mm') : null;
  }

  /**
   * Returns the meridiem value as AM or PM
   * @param priorityTime an ISO DateTime string
   */
  static getMeridiem(priorityTime: string): string {
    return priorityTime ? DateTime.fromISO(priorityTime, { zone: 'utc' }).toLocal().toFormat('a') : null;
  }

  static getUTC(formValues: ExtendedRideRequest): {
    rideDate: Date, pickupTime: Date | undefined, dropoffTime: Date | undefined
  } {
    const { pickupTimeString, dropoffTimeString } = formValues;
    const date = DateTime.fromISO(formValues.dateString, { setZone: true });
    const pickup = pickupTimeString ? date.set(TimeHelperService.getTimeSplit(pickupTimeString)) : null;
    const dropOff = dropoffTimeString ? date.set(TimeHelperService.getTimeSplit(dropoffTimeString)) : null;
    return {
      rideDate: date.toUTC().toJSDate(),
      pickupTime: pickup?.toUTC().toJSDate() || null,
      dropoffTime: dropOff?.toUTC().toJSDate() || null,
    };
  }

  static getTimeSplit(time: string): { hour: number, minute: number } {
    if (!time) { return null; }
    const [hours, minutes] = time.split(':');
    return { hour: Number(hours), minute: Number(minutes) };
  }
}
