import { Component, OnDestroy, OnInit } from '@angular/core';

import { DeviceService } from './shared/services/device.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from '../environments/environment';
import { AuthenticationService } from './shared/services/authentication.service';
import { VersionService } from './shared/services/version.service';
import { distinctUntilChanged, filter, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  public readonly title = environment.appName;
  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly device: DeviceService,
    private readonly auth: AuthenticationService,
    private readonly versionService: VersionService,
    private readonly router: Router,
    title: Title,
  ) {
    this.handleIcons();
    title.setTitle(this.title);
  }

  async ngOnInit(): Promise<void> {
    this.versionService.binaryOutOfDate$.pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged(),
      filter(value => value),
      switchMap(() => this.router.navigate(['/out-of-support'])),
    ).subscribe();


    await this.auth.init();
    console.log('🔍', 'Initializing...');
    await this.device.registerDevice();
    console.log('🔍', 'Initialized!');
  }

  /**
   * Adds custom icons to be used in mat-icon.
   * Usage: <mat-icon svgIcon="iconName"></mat-icon>
   */
  handleIcons() {
    //  keep in alphabetical order to make it easier to see what is there
    const icons = [
      'comments',
    ];
    icons.forEach(icon => {
      const path = `../assets/icons/${icon}.svg`;
      const sanitizedPath = this.sanitizer.bypassSecurityTrustResourceUrl(path);
      this.iconRegistry.addSvgIcon(icon, sanitizedPath);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
