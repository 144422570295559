import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../../../environments/environment';
import { VersionService } from '../../services/version.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  private readonly _router = inject(Router);
  private readonly _auth = inject(AuthenticationService);
  private readonly _versionService = inject(VersionService);

  public readonly currentYear: number = new Date().getFullYear();
  public readonly features = environment.features;
  public readonly companyName = environment.companyName;
  public readonly version: string = this._versionService.buildVersion;
  public readonly snapshotInfo$ = this._versionService.snapshotInfo$;

  public readonly returnUrl$: Observable<string> = this._router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.url),
  );

  public readonly userIsLoggedIn$: Observable<boolean> = this._auth.currentUser$.pipe(
    map(user => !!user),
  );

  public readonly showActions$ = this._router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map((event: NavigationEnd) => !this._hideActions(event.url)),
      tap(value => console.log(value)),
      startWith(!this._hideActions(this._router.url)),
    );

  private _hideActions(url: string): boolean {
    const hideBackBtnUrls: string[] = ['/out-of-support'];
    return hideBackBtnUrls.includes(url);
  }


}
