<mat-error>Oops, something went wrong!</mat-error>
<ng-container *ngIf="data && data !== 'OK'; else defaultErrorTemplate">
  <p>{{ data }}</p>
</ng-container>
<div class="btn-container">
  <button type="button" mat-button class="alternate" (click)="retry()">
    Retry
  </button>
  <button type="button" mat-button class="" (click)="close()">Close</button>
</div>

<ng-template #defaultErrorTemplate>
  <p>For assistance, please contact support:</p>
</ng-template>
