import { RideStatusType } from '../services/nswag/service-proxies';

const _statuses: RideStatusType[] = [
  { rideStatusId: 1, name: 'Requested', toolTip: "Requested Ride", icon: 'pending', iconColor: '#263369' },
  { rideStatusId: 2, name: 'Denied', icon: 'cancel', toolTip: "Denied Ride", iconColor: '#B71C1C' },
  { rideStatusId: 3, name: 'Cancelled', icon: 'block', toolTip: "Cancelled Ride", iconColor: '#6b6b6b' },
  { rideStatusId: 4, name: 'Accepted', icon: 'thumb_up_alt', toolTip: "Accepted Ride", iconColor: '#8cc63f' },
];

export const STATUSES = {
  list: [..._statuses],
  maps: {
    name: _statuses.reduce((acc, item) => ({ ...acc, [item['name']]: item }), {}),
    id: _statuses.reduce((acc, item) => ({ ...acc, [item['rideStatusId']]: item }), {}),
    rideStatusId: _statuses.reduce((acc, item) => ({ ...acc, [item['rideStatusId']]: item }), {}),
  },
  getById: (id: number) => _statuses.find(status => status.rideStatusId === id),
  getByName: (name: string) => _statuses.find(status => status.name === name),
};
