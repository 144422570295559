import packageJson from 'package.json';
import { environment } from '../../environments/environment';
// Note: do not check in over this. When we run locally, we will be able to identify local testing using this.
export const buildInfo = {
  name: 'TripPortal',
  ionicAppId: environment.ionicAppId,
  ionicAppChannel: `dev.${packageJson.version}[0]`,
  publicVersion: packageJson.version,
  internalVersion: '2024.11.15-16.52.37.102',
  date: '2024-11-15T16:52:37.102Z',
};
