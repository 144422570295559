import { AbstractControl } from '@angular/forms';
import { AddressModel } from '../services/nswag/service-proxies';

export function addressValidator(
  control: AbstractControl<AddressModel>,
): { [key: string]: any } | null {
  if (control.value) {
    const hasLat = control.value.latitude && Math.abs(control.value.latitude) > 0 ? true : false;
    const hasLng = control.value.longitude && Math.abs(control.value.longitude) > 0 ? true : false;
    const hasZip = control.value.zip && control.value.zip.length > 0 ? true : false;
    const valid = hasLat && hasLng && hasZip;
    return valid ? null : { missingValue: { valid: false, value: control.value } };
  }
  return null;
}
