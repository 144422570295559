import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss'],
})
export class ErrorDisplayComponent {
  constructor(
    private snackbarRef: MatSnackBarRef<ErrorDisplayComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data?: string,
  ) { }

  close() {
    this.snackbarRef.dismiss();
  }

  retry() {
    window.location.reload();
  }
}
