import { AddressModel, ServiceProxy } from '../../shared/services/nswag/service-proxies';
import { AsyncSubject, BehaviorSubject, Observable, ReplaySubject, combineLatest, firstValueFrom, forkJoin, of } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';

import { AddressParserService } from './address-parser.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { LocationModel } from 'src/app/models/location';

@Injectable({
  providedIn: 'root',
})
export class AddressSearchService {

  public readonly previousRiderLocations$ = new ReplaySubject<LocationModel[]>(1);

  constructor(
    private ngZone: NgZone,
    private addressParser: AddressParserService,
    private proxies: ServiceProxy,
    private auth: AuthenticationService,
  ) {
  }

  async refreshRiderAddressHistory(): Promise<LocationModel[]> {
    try {
      const user = await firstValueFrom(this.auth.currentUser$);
      const addresses = user && !user.isDelegateUser ? await firstValueFrom(this.proxies.addressHistory()) : [];
      const mappedAddresses = addresses.map(address => ({
          description: `${address.name ? address.name + ' - ' : ''} ${address.address1} ${address.address2} ${address.city}, ${address.state}`,
          coords: { lat: address.latitude, lng: address.longitude },
          address,
          isGoogleResult: false,
      }));
      this.previousRiderLocations$.next(mappedAddresses);
      return mappedAddresses;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  search(
    vendorRiderId: string,
    searchText: string,
    autoCompleteService: google.maps.places.AutocompleteService,
  ): Observable<LocationModel[]> {
    const sanitizedSearchText = searchText?.trim()?.toUpperCase();
    const validSearch = sanitizedSearchText.length >= 2;

    const googleSearch$ = this.loadGooglePlaces(validSearch, sanitizedSearchText, autoCompleteService);

    return forkJoin([
      firstValueFrom(this.loadPreviousLocations(vendorRiderId)).catch(err => { console.error(err); return []; }),
      firstValueFrom(googleSearch$),
    ]).pipe(
      map(([addresses, predictions]) => {
        const previousLocations: LocationModel[] = addresses
          .filter(item => !validSearch || item.description.toUpperCase().includes(sanitizedSearchText));

        const googleLocations: LocationModel[] =
          predictions.map(location => {
            return {
              id: location.place_id,
              description: location.description,
              coords: { lat: 0, lng: 0 },
              address: undefined,
              isGoogleResult: true,
            };
          });

        return [...previousLocations, ...googleLocations];
      }),
    );
  }

  /** Get previously used locations for a user */
  loadPreviousLocations(vendorRiderId: string): Observable<LocationModel[]> {

    const user = this.auth.getCurrentUser();

    if (!user) { return of([]); }
    if (!user.isDelegateUser) { return this.previousRiderLocations$; }
    if (!vendorRiderId) { return of([]); }

    return this.proxies.delegateRiderAddressHistory(vendorRiderId).pipe(
      map((addresses: AddressModel[]) => addresses.map(address => {
        return {
          description: `${address.name ? address.name + ' - ' : ''} ${address.address1} ${address.address2} ${address.city}, ${address.state}`,
          coords: { lat: address.latitude, lng: address.longitude },
          address,
          isGoogleResult: false,
        };
      })),
    );
  }

  geocodeAddress(
    address: AddressModel,
    geocoder: google.maps.Geocoder,
  ): Promise<AddressModel> {
    return new Promise((resolve, reject) => {
      if (!address) {
        reject('Address not found');
      }
      const searchCriteria = `${address.address1}, ${address.city}, ${address.state}  ${address.zip}`;
      geocoder.geocode({ address: searchCriteria }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject('Cannot geocode this address.');
        }
        const place: google.maps.GeocoderResult = results[0];
        const coordinates = place.geometry.location.toJSON();
        resolve({
          ...address,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        });
      });
    });
  }

  getAddressFromGeoCode(
    event: google.maps.MapMouseEvent,
    geocoder: google.maps.Geocoder,
  ): Promise<AddressModel> {
    return new Promise((resolve, reject) => {
      if (!location) {
        reject('Coordinates not found');
      }
      geocoder.geocode({ location: event.latLng }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject('Cannot geocode this location.');
        }
        const place: google.maps.GeocoderResult = results[0];
        const address = this.addressParser.parseAddressFromPlace(place);
        const coordinates = place.geometry.location.toJSON();
        resolve({
          ...address,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        });
      });
    });
  }

  loadGooglePlaces(
    hasCriteria: boolean,
    searchCriteria: string,
    autoCompleteService: google.maps.places.AutocompleteService,
  ): Observable<google.maps.places.AutocompletePrediction[]> {
    if (!hasCriteria) {
      return of([]);
    }
    const subject = new AsyncSubject<google.maps.places.AutocompletePrediction[]>();
    autoCompleteService.getPlacePredictions({
      input: searchCriteria,
      componentRestrictions: { country: 'us' },
    },
      (predictions, status) => {
        if (
          status !== google.maps.places.PlacesServiceStatus.OK &&
          status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS
        ) {
          subject.error(
            'Something went wrong with that location - please try another one.',
          );
        } else {
          subject.next(predictions || []);
        }
        subject.complete();
      },
    );
    return subject.asObservable();
  }

  getPlaceDetails(
    id: string,
    placesService: google.maps.places.PlacesService,
  ): Promise<AddressModel> {
    return new Promise((resolve, reject) => {
      placesService.getDetails({ placeId: id }, (result, status1) => {
        if (status1 !== google.maps.places.PlacesServiceStatus.OK) {
          reject(
            'Something went wrong with that location - please try another one.',
          );
        }
        const address = this.addressParser.parseAddressFromPlace(result);
        resolve(address);
      });
    });
  }

  getCurrentDeviceLocation(): Observable<google.maps.LatLngLiteral> {
    return new Observable<google.maps.LatLngLiteral>((observer) => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            const latLng: google.maps.LatLngLiteral = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.ngZone.run(() => {
              observer.next(latLng);
              observer.complete();
            });
          },
          (error) => observer.error(error),
        );
      } else {
        observer.error('Unsupported Browser');
      }
    });
  }
}
