<ng-container *ngIf="apiLoaded">
  <ng-container [formGroup]="searchGroup" *ngIf="showSearch">
    <label [ngClass]="{ required: required }" for="addressSearch">
      Smart Search
      <span class="hint">Start typing to search for an address</span>
    </label>

    <input id="addressSearch" autocorrect="off" autocapitalization="off" spellcheck="off" autocomplete="off"
      type="search" placeholder="Select or search for address." matInput formControlName="searchInput"
      [matAutocomplete]="auto" (keydown)="preventSubmission($event)" #addressSearch
      [ngClass]="{'invalid': addressInValid }">

    <ng-container *ngIf="searchGroup.get('searchInput').touched">
      <mat-error *ngIf="parentForm?.controls?.[controlName]?.invalid">This field is required</mat-error>
      <mat-error *ngIf="searchGroup.get('searchInput').invalid">Please select a value from the dropdown</mat-error>
    </ng-container>

    <input autocomplete="off" autocorrect="off" autocapitalization="off" spellcheck="off" type="text"
      aria-label="Address Line 2" placeholder="Optional Address Information (house, appt, notes)" matInput
      formControlName="address2" />

    <mat-autocomplete [displayWith]="getDisplayAddress" (optionSelected)="updateLocationFromSelection($event)"
      #auto="matAutocomplete">
      <mat-option *ngFor="let option of locationOptions" [value]="option">
        <span *ngIf="!option.isGoogleResult" class="favorite">⭐</span>
        <span [ngClass]="{
            'non-geocoded': !isGeoCoded(option),
            geocoded: isGeoCoded(option)
          }">
          {{ option.description }}
        </span>
      </mat-option>
    </mat-autocomplete>
  </ng-container>

  <div class="map">
    <google-map #map [options]="options" height="" width="" [center]="options.center">
      <map-marker *ngIf="marker" [position]="marker.position" [options]="marker.options"
        (mapDragend)="updateLocationOnDragEnd($event)">
      </map-marker>
    </google-map>
  </div>
</ng-container>