import { RequestRideResponse, RideRequest, TransportationProviderModel, UserProfile } from '../../shared/services/nswag/service-proxies';

import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { MapsService } from '../../shared/services/maps.service';
import { PortalUserRole } from 'src/app/shared/enums/user-role.enum';
import { ReservationType } from 'src/app/shared/enums/reservation-type.enum';
import { TimeHelperService } from 'src/app/shared/services/time-helper.service';
import { UntypedFormGroup } from '@angular/forms';
import { ValuesService } from '../../shared/services/values.service';
import { firstValueFrom } from 'rxjs';
import { RideRequirementType } from '../../shared/enums/ride-requirement.enum';
import { ExtendedRideRequest } from '../../shared/models/ride.model';

@Injectable({
  providedIn: 'root',
})
export class RequestBuilderService {
  static DEFAULT_LOCATION: google.maps.LatLngLiteral = {
    lat: 39.8283459,
    lng: -98.5794797,
  };

  deviceLocation: google.maps.LatLngLiteral;

  /** Holds a new unregistered rider (delegate view) to be submitted along with the ride. */
  newRider: UserProfile;

  /** Holds found provider which will be providing the ride */
  transportationProvider: TransportationProviderModel;
  availableTransportationProviders: TransportationProviderModel[];
  requestRideResponse: RequestRideResponse;
  pickupTime: string;

  formValues: UntypedFormGroup['value'] = {};
  isReturn: boolean = false;

  constructor(
    private authSvc: AuthenticationService,
    private valueSvc: ValuesService,
  ) {
    let fallbackLocation: google.maps.LatLngLiteral;
    const currentUser = this.authSvc.getCurrentUser();
    const transportationProvider = currentUser?.transportationProvider;
    if (transportationProvider?.defaultLatitude && transportationProvider?.defaultLongitude) {
      fallbackLocation = { lat: transportationProvider.defaultLatitude, lng: transportationProvider.defaultLongitude };
    } else {
      fallbackLocation = MapsService.DEFAULT_CENTER;
    }

    if (!currentUser?.isDelegateUser) {
      navigator.geolocation.getCurrentPosition(
        value => {
          this.deviceLocation = {
            lat: value.coords.latitude,
            lng: value.coords.longitude,
          };
        },
        () => { this.deviceLocation = fallbackLocation; },
      );
    } else {
      this.deviceLocation = fallbackLocation;
    }
  }

  /** Lists all available transportation providers for the current user. */
  listTransportationProviders(): TransportationProviderModel[] {
    return this.availableTransportationProviders;
  }

  /** Returns current user as a number. Rider = 1, Delegate = 2 */
  getCurrentUserRole(): PortalUserRole {
    return this.authSvc.getCurrentUser().isDelegateUser ? 2 : 1;
  }

  /** get return values for form */
  getReturnRideValues(value: UntypedFormGroup['value']) {
    const returnReservationType = ReservationType.Pickup;
    const priorityTime = value.pickupTimeString || value.dropoffTimeString;


    const returnValues = {
      reservationTypeId: returnReservationType,
      dateString: value.dateString,
      pickupAddress: value.dropoffAddress,
      dropoffAddress: value.pickupAddress,
      pickupTimeString: TimeHelperService.getReturnTime({ date: value.dateString, priorityTime }),
      dropoffTimeString: null,
      returnRide: true,
    };

    return returnValues;
  }

  reset(requestForm?: UntypedFormGroup): void {
    this.newRider = null;
    this.formValues = {};
    this.isReturn = false;
    this.transportationProvider = null;
    this.availableTransportationProviders = null;
    this.pickupTime = null;
    this.requestRideResponse = null;

    if (requestForm) {
      // reset form values
      const startDateTime: DateTime = DateTime.now().plus({ hours: 2 });
      const startDate: string = startDateTime.toFormat('yyyy-MM-dd');
      const startPickUpTime: string = startDateTime.toFormat('HH:mm');

      requestForm.reset({
        date: startDate,
        pickupTime: startPickUpTime,
        dropoffTime: startPickUpTime,
      });
    }
  }

  async mapRides(body: any[]): Promise<ExtendedRideRequest[]> {
    const requirementTypes = await firstValueFrom(this.valueSvc.getRideRequirementTypes());
    return body.map(_ride => {
      const ride = { ..._ride };
      const rideDate = ride.dateString;
      const pickupTimeString = ride.pickupTimeString;
      const dropoffTimeString = ride.dropoffTimeString;
      const escorts = ride.escorts ?? 0;
      const fareAmount = ride.fareAmount ?? 0;

      const rideRequirements = [];

      if (ride.wheelchairRequired) {
        rideRequirements.push(requirementTypes.getById(RideRequirementType.Wheelchair).rideRequirementId);
      }
      if (ride.stretcherRequired) {
        rideRequirements.push(requirementTypes.getById(RideRequirementType.Stretcher).rideRequirementId);
      }
      if (ride.liftRequired) {
        rideRequirements.push(requirementTypes.getById(RideRequirementType.Lift).rideRequirementId);
      }

      const formattedPickupTime = pickupTimeString ?
        String(TimeHelperService.getDateTime(rideDate, pickupTimeString, 'ISO')).split('.')[0] + 'Z' : null;
      const formattedDropoffTime = dropoffTimeString ?
        String(TimeHelperService.getDateTime(rideDate, dropoffTimeString, 'ISO')).split('.')[0] + 'Z' : null;
      const formattedRideDate = ride.dateString ?
        String(TimeHelperService.getDateTime(rideDate, dropoffTimeString, 'ISO')).split('.')[0] + 'Z' : null;

      ride.pickupTime = formattedPickupTime;
      ride.dropOffTime = formattedDropoffTime;
      ride.rideDate = formattedRideDate;
      ride.rideRequirements = rideRequirements;
      ride.escorts = escorts;
      ride.fareAmount = fareAmount;

      return ride;
    });
  }
}
