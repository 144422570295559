import '@angular/compiler';

import { AppModule } from './app/app.module';
import { TrackJS } from 'trackjs';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  enableProdMode();
}

// Initialize TrackJS for error handling
if (environment.TrackJS) {
  TrackJS.install(environment.TrackJS);
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});

const host = window.location.host;
let base: string;

switch (host) {
  case 'localhost:4203': base = 'http://localhost:44316'; break;
  case 'localhost:8100': base = localStorage.getItem('TMRiderPortalApiEndpointUrl') || 'https://api.requestmyrides.com'; break;
  case 'requestmyrides.com': base = 'https://api.requestmyrides.com'; break;
  default: base = `https://api-${host}`;
}
// Not the same as apiBaseUrl
if (!environment.apiUrl) {
  environment.apiUrl = `${base}`;
}
