<div class="container default-background" cdkScrollable>

  <div>
    <h1>Out Of Support</h1>
    <p>The app version currently on your device is no longer supported.</p>
    <p>Please download the latest version from the {{ storeName }}.</p>
    <dl *ngIf="snapshotInfo$ | async as snapshotInfo" class="build-info">
      <dt>Current Version:</dt> <dd>{{ version }}</dd>
      <dt>Current Build Id:</dt> <dd>{{ snapshotInfo?.buildId }}</dd>
    </dl>
  </div>

  <app-footer />

</div>
