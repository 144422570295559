import packageJson from 'package.json';

import { App, AppInfo } from "@capacitor/app";
import { Device, DeviceInfo } from "@capacitor/device";

import { Capacitor } from '@capacitor/core';
import { IEnvironmentInformation } from '../../models/environment-information.model';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { buildInfo } from '../../config/build-info';

@Injectable({
  providedIn: 'root',
})
export class PluginService {

  private cachedEnvironmentInfo: IEnvironmentInformation | null = null;

  constructor() { }

  public get isNativePlatform(): boolean {
    return Capacitor.isNativePlatform();
  }

  public async getEnvironmentInfo(forceCacheRefresh: boolean = false): Promise<IEnvironmentInformation> {
    // if cached environment info exists, return that (unless a refresh is requested)
    if (!forceCacheRefresh && !!this.cachedEnvironmentInfo) return this.cachedEnvironmentInfo;

    const deviceInfo: DeviceInfo = await Device.getInfo();
    const appInfo: AppInfo = Capacitor.isNativePlatform()
      ? await App.getInfo()
      : {
        name: environment.appName,
        id: environment.appId,
        build: buildInfo.publicVersion.replace(/\./gmi, ''),
        version: buildInfo.publicVersion,
      };

    this.cachedEnvironmentInfo = {
      // using capacitor device plugin
      uuid: '',
      ...(await Device.getId()),
      ...appInfo,
      model: deviceInfo.model,
      platform: deviceInfo.platform,
      platformVersion: deviceInfo.webViewVersion,
      manufacturer: deviceInfo.manufacturer,
      capacitorVersion: packageJson.dependencies['@capacitor/core'],

      // using capacitor app plugin
      playStoreVersion: appInfo?.version,
      buildVersion: appInfo?.build,
      applicationId: appInfo?.id,
      applicationName: appInfo?.name,
    };

    return this.cachedEnvironmentInfo;
  }

}
