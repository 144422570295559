import { DeviceModel } from './nswag/service-proxies';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageService } from './storage.service';
import { Subject } from 'rxjs';
import { TrackJS } from 'trackjs';
import { environment } from 'src/environments/environment';

export enum LogLevel {
  None = -1, // Disable all logging
  Fatal = 0, // If you have a pager, it goes off when one of these occurs.
  Warning = 1, // Service is degraded or endangered.
  Debug = 2, // Internal system events that aren't necessarily observable from the outside.
  Information = 3, // The lifeblood of operational intelligence - things happen.
  Verbose = 4, // Anything and everything you might want to know about a running block of code.
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  // private originalConsole = {
  //     log: console.log,
  //     warn: console.warn,
  //     error: console.error,
  //     trace: console.trace,
  //     info: console.info
  // };
  public backupConsole = {
    log: null,
    warn: null,
    error: null,
    trace: null,
    info: null,
  };
  private deviceInfo = '';
  private sessionId = '';
  private version = '';
  private isTrackJsInstalled = false;

  private verbosityStorageKey = 'verbosity';
  private _verbosity: LogLevel = LogLevel.None;

  private maxMessagesToKeepInMemory = 100;

  constructor(
    private platform: Platform,
    private storageService: StorageService,
  ) {
    this.storageService
      .get(this.verbosityStorageKey)
      .then((value) => (this.verbosity = value));
  }

  public get simpleLoggingEnabled(): boolean {
    return false;
    // return this.backupConsole.log == null;
  }

  public set simpleLoggingEnabled(enableSimpleLogging: boolean) {
    const isSimpleCurrently = this.backupConsole.log == null;
    const hasStateChanged = enableSimpleLogging !== isSimpleCurrently;
    if (!enableSimpleLogging && !this.isTrackJsInstalled && !this.isSimulator) {
      TrackJS.install({
        token: '85bb494f73664aa19e6b1b6237d458a5',
        application: environment.TrackJS.application
          ? environment.TrackJS.application
          : 'riderportalcore',
        console: { enabled: true },
      });
      this.isTrackJsInstalled = true;
      this.log('TrackJS Installed and active.');
    }
    if (hasStateChanged) {
      this.log(
        enableSimpleLogging
          ? 'Simple logging mode (without console overrides) enabled.'
          : 'Advanced logging with console overrides enabled.',
      );
      this.toggleLogOverride();

      // If this is advanced logging and we originally had simple logging enabled, force the verbosity to Fatal.
      if (!enableSimpleLogging && this._verbosity === LogLevel.None) {
        this._verbosity = LogLevel.Fatal;
      }
    }
  }

  public get verbosity() {
    if (this._verbosity !== LogLevel.Fatal) {
      // if it has not be explicitly overridden then return a value based on if we are in sim
      return this.isSimulator ? LogLevel.Verbose : LogLevel.Warning;
    }
    return this._verbosity;
  }

  public set verbosity(logLevel: LogLevel) {
    if (this._verbosity !== logLevel) {
      this.log(`Log Verbosity set to ${LogLevel[logLevel]}`);
      this._verbosity = logLevel;
      if (this.simpleLoggingEnabled !== (logLevel === LogLevel.None)) {
        this.simpleLoggingEnabled = logLevel === LogLevel.None;
      }
      this.storageService.set(this.verbosityStorageKey, logLevel).then();
    }
  }

  clearLoggedMessages() { }

  log(message: any, ...data: any[]) {
    this.handleLog(LogLevel.Verbose, message, data);
  }

  logInformation(message: string, ...data: any[]) {
    this.handleLog(LogLevel.Information, message, data);
  }

  logTrace(message: string, ...data: any[]) {
    this.handleLog(LogLevel.Debug, message, data);
  }

  logWarning(message: string, ...data: any[]) {
    this.handleLog(LogLevel.Warning, message, data);
  }

  logFatal(message: string, ...data: any[]) {
    this.handleLog(LogLevel.Fatal, message, data);
  }

  private _log(message: any, ...data: any[]) {
    this.handleLog(LogLevel.Verbose, message, data, true);
  }

  private _error(message: any, ...data: any[]) {
    this.handleLog(LogLevel.Fatal, message, data, true);
  }

  private _warn(message: any, ...data: any[]) {
    this.handleLog(LogLevel.Warning, message, data, true);
  }

  private _trace(message: any, ...data: any[]) {
    this.handleLog(LogLevel.Debug, message, data, true);
  }

  private _info(message: any, ...data: any[]) {
    this.handleLog(LogLevel.Information, message, data, true);
  }

  reportCurrentLog() {
    if (!this.simpleLoggingEnabled && this.isTrackJsInstalled) {
      try {
        TrackJS.configure({
          userId: this.deviceInfo,
          sessionId: this.sessionId,
          version: this.version,
        });
      } catch (e) {
        // about all we can do here is just write this out
        (this.backupConsole.error || console.error)(
          'Error caught attempting to report console log to track JS',
          e,
        );
      }
    }
  }

  setDeviceInfo(device: DeviceModel) {
    this.deviceInfo = `${device?.manufacturer}-${device?.model}`;
    if (this.isTrackJsInstalled) {
      TrackJS.configure({
        userId: this.deviceInfo,
      });
    }
  }

  setSessionId(sessionId: string) {
    this.sessionId = sessionId;
    if (this.isTrackJsInstalled) {
      TrackJS.configure({
        sessionId: this.sessionId,
      });
    }
  }

  setVersion(version: string) {
    this.version = version;
    if (this.isTrackJsInstalled) {
      TrackJS.configure({
        version: version,
      });
    }
  }

  private get isSimulator(): boolean {
    return !this.platform.is('hybrid');
  }

  private toggleLogOverride() {
    if (!this.backupConsole.log) {
      this.backupConsole.log = console.log;
      this.backupConsole.warn = console.warn;
      this.backupConsole.error = console.error;
      this.backupConsole.error = console.trace;
      this.backupConsole.error = console.info;
      console.log = (message, data) => this._log('🔍', message, data);
      console.warn = (message, data) => this._warn(message, data);
      console.error = (message, data) => this._error(message, data);
      console.trace = (message, data) => this._trace(message, data);
      console.info = (message, data) => this._info('ℹ️', message, data);
    } else {
      console.log = this.backupConsole.log;
      console.warn = this.backupConsole.warn;
      console.error = this.backupConsole.error;
      console.trace = this.backupConsole.trace;
      console.info = this.backupConsole.info;
      this.backupConsole.log =
        this.backupConsole.warn =
        this.backupConsole.error =
        this.backupConsole.error =
        this.backupConsole.error =
        null;
    }
  }

  private handleLog(
    logLevel: LogLevel,
    message: any,
    data?: any,
    isConsoleOverride?: boolean,
  ) {
    if (this.simpleLoggingEnabled) {
      this.logToConsoleInternal(logLevel, message, data, isConsoleOverride);
    } else {
      // log everything to the console.
      this.localLog(logLevel, message, data, isConsoleOverride);

      if (this.verbosity >= logLevel && !this.isSimulator) {
        this.remoteLog(message, data);
      }
    }
  }

  private remoteLog(message: string, data?: any) {
    data = this.prepareDataForLogging(data);
    try {
      if (data instanceof Error) {
        TrackJS.track(data);
      } else if (data) {
        TrackJS.track({ message: message, data: data });
      } else {
        TrackJS.track(message);
      }
    } catch (e) {
      // this shouldn't cause an error so eat it here
      this.logToConsoleInternal(
        LogLevel.Fatal,
        'Error caught while remote logging..',
        e,
      );
    }
  }

  private localLog(
    logLevel: LogLevel,
    message: any,
    data?: any,
    isConsoleOverride?: boolean,
  ) {
    data = this.prepareDataForLogging(data);

    this.logToConsoleInternal(logLevel, message, data, isConsoleOverride);
  }

  private logToConsoleInternal(
    logLevel: LogLevel,
    message: any,
    data?: any,
    isConsoleOverride?: boolean,
  ) {
    const args = [message];

    const isArray = data && Array.isArray(data);
    if (isArray && data.length > 0) {
      args.push.apply(args, data);
    } else if (!isArray && data) {
      args.push(data);
    }
    if (isConsoleOverride) {
      // if this is a console.xxx command, then we need to route it to the correct console command
      switch (logLevel) {
        case LogLevel.Debug:
          (this.backupConsole.trace || console.trace).apply(console, args);
          break;
        case LogLevel.Information:
          (this.backupConsole.info || console.info).apply(console, args);
          break;
        case LogLevel.Fatal:
          (this.backupConsole.error || console.error).apply(console, args);
          break;
        case LogLevel.Warning:
          (this.backupConsole.warn || console.warn).apply(console, args);
          break;
        default:
          (this.backupConsole.log || console.log).apply(console, args);
          break;
      }
    } else {
      // if this is a call directly to the loggerService and not an error, we should just call log.
      // This prevents verbose stack traces where we don't want them.
      if (logLevel === LogLevel.Fatal) {
        (this.backupConsole.error || console.error).apply(console, args);
      } else {
        (this.backupConsole.log || console.log).apply('🔍', console, args);
      }
    }
  }

  private prepareDataForLogging(data: any) {
    if (data && Array.isArray(data)) {
      if (data.length === 0) {
        data = null;
      } else if (data.length === 1) {
        data = data[0];
      }
    }
    return data;
  }
}
