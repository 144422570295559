import { environment } from "src/environments/environment";
import packageJson from 'package.json';

export function getLegacyChannelName(env: string) {
  switch(env) {
    case 'qa': return 'TripPortal.Testing';
    case 'dev': return 'TripPortal.Development';
    default: return 'TripPortal.Production';
  }
}

export function getChannelName(env: string = 'prod') {
  const majorVersion = Number(packageJson.version[0]);
  if (majorVersion <= 2) { return getLegacyChannelName(env); }
  switch(env) {
    case 'qa': return `test.${majorVersion}`;
    case 'dev': return `dev.${majorVersion}`;
    default: return `prod.${majorVersion}`;
  }
}

/** available app channels and api end points with their settings */
export const APP_CHANNELS: AppChannel[] = [
  {
    apiUrl: 'https://api.requestmyrides.com',
    name: getChannelName('prod'),
    displayName: 'Prod',
    autoUpdates: true,
    application: 'riderportalcore',
  },
  {
    apiUrl: 'https://api-qa.requestmyrides.com',
    name: getChannelName('qa'),
    displayName: 'QA',
    autoUpdates: true,
    application: 'riderportalqa',
  },
  {
    apiUrl: 'https://api-dev.requestmyrides.com',
    name: getChannelName('dev'),
    displayName: 'Dev',
    autoUpdates: true,
    application: 'riderportaldev',
  },
  {
    apiUrl: 'https://localhost:44316',
    displayName: 'Local',
    name: getChannelName('dev'),
    autoUpdates: true,
    application: 'riderportaldev',
  },
];

/** Local storage key for target env/channel - stores the api url */
export const STORAGE_KEY = 'TMRiderPortalApiEndpointUrl';

/**
* Construct the startup url for the app.
* @returns the startup URL
*/
export function ConstructStartupUrl(): string {
  const host = window.location.host;
  let startupBaseUrl = 'https://api.requestmyrides.com';
  // if (Capacitor?.isNativePlatform()) {
  //   startupBaseUrl = localStorage.getItem(STORAGE_KEY) || 'https://api.requestmyrides.com';
  // } else {
  switch (host) {
    case 'localhost:4301': startupBaseUrl = 'https://localhost:44316'; break;
    case 'localhost:8100': startupBaseUrl = localStorage.getItem(STORAGE_KEY) || 'https://api-dev.requestmyrides.com'; break;
    case 'requestmyrides.com': startupBaseUrl = 'https://api.requestmyrides.com'; break;
    default: {
      if (host.startsWith('localhost') || host.startsWith('127.0.0.1')) {
        // use dev by default if it's any other port on localhost
        startupBaseUrl = localStorage.getItem(STORAGE_KEY) || 'https://api-dev.requestmyrides.com';
      } else {
        startupBaseUrl = `https://api-${host}`;
      }
    }
  }
  // }
  return startupBaseUrl;
}

/** Map of channels for easier lookup */
export const ENV_MAP: AppChannelMap = APP_CHANNELS
  .reduce((acc, env) => ({ ...acc, [env.name]: env }), <AppChannelMap>{});

/** returns the api url to target */
export function getApiUrl(): string {
  return localStorage.getItem(this.STORAGE_KEY) || environment.apiUrl;
}

/** ionic channel names */
export type ChannelName = string;
/** environment settings. Name is the ionic channel name */

export type AppChannel = {
  apiUrl: string;
  name: ChannelName;
  displayName: string;
  autoUpdates: boolean;
  application: string;
  channelNameFromApi?: string;
  snapshotBuildId?: string;
}
export type AppChannelMap = {
  [key in ChannelName]: AppChannel
}
