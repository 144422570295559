import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertController: AlertController) {}

  presentAlert(options: any, onDismiss: () => void = null) {
    let alertObj: HTMLIonAlertElement;
    this.alertController.create(options).then((a) => {
      alertObj = a;

      // remove the alert from the array when it closes.
      alertObj.onDidDismiss().then(() => {
        if (onDismiss) {
          onDismiss();
        }
      });

      return alertObj.present();
    });
  }

  async presentAlertAsync(options: any, onDismiss: () => void = null) {
    const alertObj = await this.alertController.create(options);
    await alertObj.present();
    await alertObj.onDidDismiss();
    if (onDismiss) {
      onDismiss();
    }
  }
}
