import { Injectable, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  static _previousUrl: BehaviorSubject<string> = new BehaviorSubject(null);
  static previousUrl: Observable<string> =
    HelperService._previousUrl.asObservable();

  static emailRegex = /^\S{1,}@\S{1,}$/;
  static passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
  static phoneRegex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

  constructor() { }

  /**
   * Sets the focus on a specific element for user convenience. Typically this
   * will come from a field defined using `@ViewChild`, such as:
   * @ViewChild('email') emailElem: ElementRef;
   * @param elem the element to focus
   */
  static focus(elem: ElementRef) {
    setTimeout(() => {
      elem.nativeElement.focus();
    });
  }

  static updateUrl(previous: string) {
    HelperService._previousUrl.next(previous);
  }

}
