import { Pipe, PipeTransform } from '@angular/core';
import { RideViewModel } from '../models/ride.model';

@Pipe({
  name: 'priorityTime',
})
export class RidePriorityTimePipe implements PipeTransform {
  constructor() { }
  transform(ride: RideViewModel): string {
    return ride.pickupTimeString ?? ride.dropoffTimeString;
  }
}
