<footer>
  <div>
    <ng-container *ngIf="showActions$ | async">
      <a *ngIf="features.showAccessibilityStatement"
        [routerLink]="['/accessibility-statement']"
        [queryParams]="{ returnUrl: returnUrl$ | async }"
      >
        Accessibility Statement
      </a>

      <ng-container *ngIf="userIsLoggedIn$ | async else login">
        <a [routerLink]="['/logout']">Logout</a>
      </ng-container>
      <ng-template #login>
        <a [routerLink]="['/login']">Login</a>
      </ng-template>
    </ng-container>

    <a *ngIf="features.helpUrl" target="_blank" [href]="features.helpUrl">Help</a>
  </div>
  <div class="copyright">
    Copyright &copy; {{ currentYear }} {{ companyName }}
    <br>
    <ng-container *ngIf="version">Version: {{ version }} </ng-container>
    <ng-container *ngIf="snapshotInfo$ | async as snapshotInfo">({{ snapshotInfo?.buildId }})</ng-container>
  </div>
</footer>
