import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { AuthenticationService } from './services/authentication.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { login, password } from '../shared/services/mocks/data/login';
import { MockService } from './services/mocks/mock.service';
import { VersionService } from './services/version.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private readonly authSvc = inject(AuthenticationService);
  private readonly mock = inject(MockService);
  private readonly versionService = inject(VersionService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {


    if (this.versionService.binaryOutOfDate) {
      throw new Error('App version is out of support');
    }

    const { token, portalUserId } = this.authSvc.getCurrentUser() || {};
    const { url } = request || { url: '' };

    const body = request.body ? JSON.parse(request.body) : null;

    if (
      portalUserId === login.token
      || (
        body?.email?.toLowerCase() === login?.userProfile?.emailAddress?.toLowerCase()
        && body?.password === password
      )
    ) {
      const { success, response } = this.mock.get(request);
      console.info('ℹ️','using bypass', response);
      if (success) {
        return of(new HttpResponse({
          body: new Blob([ JSON.stringify(response) ], { type: "text/json" }),
          status: 200,
          statusText: 'Ok',
        }));
      }
    }

    if (token && url.includes(environment.apiUrl)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError(err => {
        const { status, message } = err;
        console.error('-- Intercept Error --', url, status, message);
        throw err;
      }),
    );
  }
}
