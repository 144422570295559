import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-ride',
  templateUrl: './ride.component.html',
  styleUrls: ['./ride.component.scss'],
})
export class RideComponent {
  @Input() ride: any;

  findTimeClass(type: string) {
    if (!type) {
      return '';
    }
    return type.toLocaleLowerCase().replace(/\s/g, '-');
  }

  formatDuration(duration: string): string {
    if (!duration) return '';

    const parts = duration.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    if (hours > 0) {
      return `${hours} hr ${minutes} min`;
    } else {
      return `${minutes} min`;
    }
  }
}
