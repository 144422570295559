import { DeviceModel, ServiceProxy } from './nswag/service-proxies';
import { STORAGE_KEY, SettingsService } from './settings.service';

import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { NotificationService } from './notification.service';
import { PluginService } from '../../select-ride/services/plugin.service';
import { VersionService } from '../../shared/services/version.service';
import { firstValueFrom } from 'rxjs';
import { Platform } from '@ionic/angular';


@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  device: DeviceModel;
  packageName = '';

  constructor(
    private loggingService: LoggingService,
    private settingsService: SettingsService,
    private versionService: VersionService,
    private proxies: ServiceProxy,
    private pluginService: PluginService,
    private notificationService: NotificationService,
  ) {

  }

  async registerDevice(): Promise<void> {
    try {
      console.log('🔍', 'Registering device...');
      const envInfo = await this.pluginService.getEnvironmentInfo();
      console.debug('🐛', 'Environment info:', envInfo);
      console.debug('🐛', `Selected channel: ${this.settingsService.currentChannel?.name}`);
      const selectedChannel: string = this.settingsService.currentChannel?.name;
      console.debug('🐛', `The default channel is: ${selectedChannel} ${this.settingsService.currentChannel?.apiUrl}`);

      this.device = { ...envInfo, ionicChannel: selectedChannel, ionicSnapshotBuildId: undefined, serialNumber: undefined };
      console.debug('🐛', 'Device info:', this.device);

      //  Check for store updates
      if (Capacitor.isNativePlatform()) {
        try {
            console.debug('🐛', 'Checking for store updates...');
            const response = await firstValueFrom(this.proxies.channelInfo(this.device));
            console.log('🔍', 'App needs updated:', response.storeUpdateRequired);
            this.versionService.binaryOutOfDate = response.storeUpdateRequired;
            if (response.storeUpdateRequired) {
              alert('The version of the application you are using is no longer supported, please update to the latest version.');
            }
          } catch (err) {
            this.notificationService.error(`The request to the API to get build update info failed`, 'OK', null);
            console.error(`The request to the API to get build update info failed.`);
            // clear the current selection from local storage because it obviously doesn't work.
            localStorage.removeItem(STORAGE_KEY);
            throw err;
          }
      }

      if (this.versionService.binaryOutOfDate) return;

      // we now know the channel and can check for updates;
      this.versionService.setChannel(selectedChannel);

      // verify that the settings service has been configured, then get the snapshot build id
      try {
        if (await this.versionService.verifyConfiguration()) {
          this.settingsService.currentChannel.snapshotBuildId = this.versionService?.snapshotBuildId;
        }
      } catch (err) {
        console.error('Error occurred trying to get the snapshot version for display', err);
      }

      console.debug('🐛', 'Current Channel', this.settingsService.currentChannel);

      if (this.settingsService.currentChannel.autoUpdates) {
        console.debug('🐛 Has auto updates on');
        // we already set the channel, so no need to pass it in.
        await this.versionService.checkForNewVersion();
      } else {
        console.warn(`Auto-updating the device for channel [${selectedChannel}] is turned off.`);
      }

      this.loggingService.setDeviceInfo(this.device);
    } catch (e) {
      console.error(e);
    }
  }

}
