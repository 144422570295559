import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../../environments/environment';
import { AlertService } from './alert.service';
import { ServiceProxy } from './nswag/service-proxies';
import { APP_CHANNELS, AppChannel, AppChannelMap } from './settings.utility';

/** Local storage key for target env/channel - stores the api url */
export const STORAGE_KEY = 'TMRiderPortalApiEndpointUrl';

/**
 * Construct the startup url for the app.
 * @returns the startup URL
 */
export function ConstructStartupUrl(key: string): string {
  const host = window.location.host;
  let startupBaseUrl = 'https://api.requestmyrides.com';
  if (Capacitor.isNativePlatform()) {
    startupBaseUrl = localStorage.getItem(key) || 'https://api.requestmyrides.com';
  } else {
    switch (host) {
      case 'localhost:4301': startupBaseUrl = 'https://localhost:44316'; break;
      case 'localhost:8100': startupBaseUrl = localStorage.getItem(key) || 'https://api-dev.requestmyrides.com'; break;
      case 'requestmyrides.com': startupBaseUrl = 'https://api.requestmyrides.com'; break;
      default: {
        if (host.startsWith('localhost') || host.startsWith('127.0.0.1')) {
          // use dev by default if it's any other port on localhost
          startupBaseUrl = localStorage.getItem(key) || 'https://api-dev.requestmyrides.com';
        } else {
          startupBaseUrl = `https://api-${host}`;
        }
      }
    }
  }
  return startupBaseUrl;
}


@Injectable({
  providedIn: 'root',
})

export class SettingsService {

  private readonly storageKey = STORAGE_KEY;

  /** Map of channels for easier lookup */
  private readonly envMap: AppChannelMap = APP_CHANNELS
    .reduce((acc, env) => ({ ...acc, [env.name]: env }), <AppChannelMap>{});

  public currentChannel: AppChannel = this.getCurrentChannel();

  constructor(
    private alertService: AlertService,
    private proxies: ServiceProxy,
  ) { }

  /** determines whether or not you are running prod based on the api your hitting */
  isProduction(): boolean {
    return this.envMap['TripPortal.Production'].apiUrl === environment.apiUrl;
  }

  /** Finds the channel details based on the current apiUrl */
  getCurrentChannel(): AppChannel {
    return APP_CHANNELS.find(channel => channel.apiUrl === environment.apiUrl) || APP_CHANNELS[0];
  }

  /** Displays menu to change the channel and updates app accordingly */
  public changeChannel(): void {
    const buildId = this.currentChannel?.snapshotBuildId && this.currentChannel.snapshotBuildId !== 'Unknown' ? this.currentChannel.snapshotBuildId : undefined;
    const alertOptions = {
      cssClass: 'device-selection-alert',
      header: 'Select Channel',
      subHeader: `Ionic Channel: ${this.currentChannel.displayName} (${this.currentChannel.name}) ${(!!buildId ? (' (Build #' + buildId + ')') : '')}`,
      message: '',
      buttons: [
        { text: 'Cancel', cssClass: 'primary' },
        {
          text: 'OK',
          cssClass: 'secondary',
          handler: (selectedEnv: AppChannel) => this.onChannelChange(selectedEnv),
        },
      ],
      inputs: APP_CHANNELS.map(channel => ({
        type: 'radio',
        label: `${channel.displayName} (${channel.name})`,
        value: channel,
        checked: channel.apiUrl === environment.apiUrl,
      })),
    };
    this.alertService.presentAlert(alertOptions);
  }

  /**
   * Change environment - using 5 click on login screen.
   * Will cause the app to reload in order to re-initiate the proxies with the correct base URL */
  private onChannelChange(selectedChannel: AppChannel): void {
    localStorage.setItem(this.storageKey, selectedChannel.apiUrl);
    location.reload();
  }
}
