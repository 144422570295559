import { Component, inject, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { VersionService } from '../shared/services/version.service';

@Component({
  selector: 'app-out-of-support',
  templateUrl: './out-of-support.component.html',
  styleUrls: ['./out-of-support.component.scss'],
})
export class OutOfSupportComponent {

  private readonly versionService = inject(VersionService);
  public readonly snapshotInfo$ = this.versionService.snapshotInfo$.pipe();
  public version: string = this.versionService.buildVersion;

  public get storeName() {
    switch (Capacitor.getPlatform()) {
      case 'ios': return 'App Store';
      case 'android': return 'Play Store';
      default: return 'Store';
    }
  }

}
