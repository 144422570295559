import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, combineLatest, firstValueFrom, interval, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, startWith, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { AddressModel } from '../../services/nswag/service-proxies';
import { AddressSearchService } from '../../../select-ride/services/address-search.service';
import { GoogleMap } from '@angular/google-maps';
import { LocationModel } from 'src/app/models/location';
import { MapsService } from '../../services/maps.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NotificationService } from '../../services/notification.service';
import { RequestBuilderService } from '../../../select-ride/services/request-builder.service';
import { RideRequestFormGroup } from '../../models/ride.model';
import { addressValidator } from '../../validators/address.validator';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  parentForm: RideRequestFormGroup;
  searchGroup = new FormGroup({
    searchInput: new FormControl<string | AddressModel>('', [addressValidator]),
    address2: new FormControl<string>(''),
  });

  private readonly destroyed$ = new Subject<boolean>();
  public readonly apiLoaded$: Observable<boolean> = this.mapsService.isLoaded$;
  public apiLoaded = false;

  public readonly options: google.maps.MapOptions = this.mapsService.DEFAULT_OPTIONS;


  @Input() controlName: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() showSearch: boolean = true;

  //  Google Maps
  autoCompleteService: google.maps.places.AutocompleteService;
  placesService: google.maps.places.PlacesService;
  geoCoderService: google.maps.Geocoder;

  //  Address Search
  @ViewChild('addressSearch') searchElementRef: ElementRef;
  locationOptions: LocationModel[] = [];
  searchLocations: LocationModel[] = [];
  previouslyUsedLocations: LocationModel[] = [];
  addressInValid: boolean;

  //  Map
  @ViewChild('map') googleMap!: GoogleMap;
  @Input() marker: {
    position: google.maps.LatLngLiteral;
    options?: google.maps.MarkerOptions;
  } = {
      position: RequestBuilderService.DEFAULT_LOCATION,
      options: { draggable: true },
    };

  @Output() loaded = new EventEmitter<boolean>();
  @Output() addressChange = new EventEmitter<Partial<AddressModel>>();

  constructor(
    private readonly mapsService: MapsService,
    private readonly addressSearchService: AddressSearchService,
    private readonly controlContainer: ControlContainer,
    private readonly notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control as RideRequestFormGroup;
    this.addressInValid = this.parentForm.controls[this.controlName]?.invalid;

    // search control with initial value
    if (this.controlName) {
      this.parentForm.get(this.controlName)?.
        valueChanges.pipe(
          startWith(this.parentForm.get(this.controlName)?.value),
          distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
          takeUntil(this.destroyed$),
        ).subscribe((value) => {
          if (value) { this.searchGroup.get('searchInput').patchValue(value); }
          this.searchGroup.get('address2').patchValue(value?.address2);
          this.addressInValid = this.parentForm.controls[this.controlName].invalid;
        });
    }

    /** Load google maps api */
    this.apiLoaded$
      .pipe(
        tap((loaded) => (this.apiLoaded = loaded)),
        takeWhile((loaded) => !loaded, true),
      )
      .subscribe((loaded) => {
        this.loaded.emit(loaded);

        /**
         * every 10 millisecond check to see if google maps services are loaded yet until they are,
         * then set them up for use
         */
        interval(10)
          .pipe(
            takeWhile(() => !window.google?.maps?.places, true),
            finalize(() => {
              /** Once google services are available set them up for use */
              this.geoCoderService = new window.google.maps.Geocoder();
              this.autoCompleteService =
                new window.google.maps.places.AutocompleteService();
              if (this.searchElementRef?.nativeElement) {
                this.placesService =
                  new window.google.maps.places.PlacesService(
                    this.searchElementRef.nativeElement,
                  );
              }
              this.handleMarker();
            }),
          )
          .subscribe();
      });

    /** Set up address input to listen for changes so that we can update list options accordingly */
    const vendorIdControl = this.parentForm.get('vendorRiderId');
    combineLatest([
      this.searchGroup.get('searchInput')?.valueChanges.pipe(startWith(''), debounceTime(250)),
      vendorIdControl
        ? vendorIdControl.valueChanges.pipe(startWith(vendorIdControl.value))
        : of(''),
    ]).pipe(
      takeUntil(this.destroyed$),
    ).subscribe(([searchTerm, riderVendorId]) => {
      this.searchFilterChanged(searchTerm);
    });

    /** update address when line 2 changes */
    this.searchGroup.get('address2').valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.destroyed$),
    ).subscribe((value) => {
      const address = this.searchGroup.get('searchInput').value;

      if (this.controlName && address && typeof address !== 'string') {
        this.parentForm
          .get(this.controlName)
          .patchValue({ ...address, address2: value });
      }
      this.addressChange.emit({ address2: value });
    });
  }

  /** Handle input changes */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.marker) {
      this.handleMarker();
    }
    if (changes.disabled) {
      const searchControl = this.searchGroup.get('searchInput');
      this.disabled ? searchControl.disable() : searchControl.enable();
      //  need to create a new marker object because map detection changes does not trigger on mutations
      if (this.marker?.options) {
        this.marker = {
          ...this.marker,
          options: { draggable: !this.disabled },
        };
      }
    }
  }

  /** If map is loaded, add marker to map */
  handleMarker() {
    if (!this.apiLoaded || !this.marker?.position) {
      return;
    }
    this.googleMap.panTo(this.marker.position);
  }

  /** If address value is a string, search for location options */
  async searchFilterChanged(searchText: string | AddressModel): Promise<void> {
    if (typeof searchText !== 'string') { return; };
    this.parentForm.get(this.controlName)?.patchValue(undefined);
    const riderId = this.parentForm.controls.vendorRiderId?.value || null;

    this.locationOptions = await firstValueFrom(
      this.addressSearchService.search(riderId, searchText, this.autoCompleteService),
    );
  }

  /** update address information when user selects a location from the dropdown */
  public async updateLocationFromSelection(
    $event: MatAutocompleteSelectedEvent,
  ) {
    if (!this.apiLoaded) { return; }
    try {
      const location = $event.option.value as LocationModel;
      this.searchElementRef.nativeElement.value = location.description;

      let address: AddressModel = location.address;
      if (location.isGoogleResult) {
        address = await this.addressSearchService.getPlaceDetails(
          location.id.toString(),
          this.placesService,
        );
      } else if (!this.isGeoCoded(location)) {
        address = await this.addressSearchService.geocodeAddress(
          location.address,
          this.geoCoderService,
        );
      }
      this.updateLocationFromAddress(address);
    } catch (err) {
      console.debug('🐞', err);
      this.notificationService.error(err, null, 4000);
    }
  }

  /** Update form when pin location is changed on the map */
  async updateLocationOnDragEnd(
    event: google.maps.MapMouseEvent,
  ): Promise<void> {
    try {
      const searchInputControl = this.searchGroup.get('searchInput');
      const address = await this.addressSearchService.getAddressFromGeoCode(
        event,
        this.geoCoderService,
      );

      searchInputControl.patchValue(address);
      this.updateLocationFromAddress(address);
    } catch (err) {
      console.debug('🐞', err);
      this.notificationService.error(err, null, 4000);
    }
  }

  /** Returns display value for selected location in autocomplete control */
  public getDisplayAddress(option?: AddressModel): string {
    if (!option?.address1) {
      return '';
    }
    return `${option.address1}, ${option.city}, ${option.state}`;
  }

  /** Prevent form submission when user uses enter key to select a location */
  public preventSubmission($event: KeyboardEvent): void {
    if ($event.key !== 'enter') {
      return;
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  /** Update maker and form when address is selected */
  private updateLocationFromAddress(address: AddressModel) {
    if (!address) { return; }
    this.marker.position = { lat: address.latitude, lng: address.longitude };
    if (this.controlName) {
      this.parentForm.get(this.controlName)?.patchValue(address);
    }
    this.addressChange.emit(address);
    this.handleMarker();
  }

  /** Check to see if address is already geo coded */
  isGeoCoded(location: LocationModel): boolean {
    return (
      location.isGoogleResult ||
      !!(location.coords && location.coords.lat && location.coords.lng)
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
