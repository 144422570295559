<div class="container default-background" cdkScrollable>
  <h1>
    Accessibility Statement for
    <span class="basic-information website-name">TripMaster</span>
  </h1>
  <p>
    <span class="basic-information organization-name">CTS Software</span>
    is committed to ensuring digital accessibility for people with disabilities.
    We are continually improving the user experience for everyone and applying
    the relevant accessibility standards.
  </p>
  <h2>Measures to support accessibility</h2>
  <p>
    <span class="basic-information organization-name">CTS Software</span>
    takes the following measures to ensure accessibility of
    <span class="basic-information website-name">TripMaster</span>:
  </p>
  <ul class="organizational-effort accessibility-measures">
    <li>Include accessibility as part of our mission statement.</li>
    <li>Include accessibility throughout our internal policies.</li>
    <li>Integrate accessibility into our procurement practices.</li>
  </ul>
  <h2>Conformance status</h2>
  <p>
    The
    <a
      rel="noopener"
      target="_blank"
      href="https://www.w3.org/WAI/standards-guidelines/wcag/"
      >Web Content Accessibility Guidelines (WCAG)</a
    >
    defines requirements for designers and developers to improve accessibility
    for people with disabilities. It defines three levels of conformance: Level
    A, Level AA, and Level AAA.
    <span class="basic-information website-name">TripMaster</span>
    is
    <span
      class="basic-information conformance-status"
      data-printfilter="lowercase"
      >fully conformant</span
    >
    with
    <span class="basic-information conformance-standard"
      ><span data-negate="">WCAG 2.0 level AA</span>.
    </span>
    <span>
      <span class="basic-information conformance-status">Fully conformant</span>
      means that
      <span class="basic-information conformance-meaning"
        >the content fully conforms to the accessibility standard without any
        exceptions</span
      >.
    </span>
  </p>
  <h2>Feedback</h2>
  <p>
    We welcome your feedback on the accessibility of
    <span class="basic-information website-name">TripMaster</span>. Please let
    us know if you encounter accessibility barriers on
    <span class="basic-information website-name">TripMaster</span>:
  </p>
  <p>
    <span class="basic-information conformance-meaning">
      If you are having any issues logging in please contact the transportation
      provider you are trying to request a ride from directly.
    </span>
  </p>
  <h2>Compatibility with browsers and assistive technology</h2>
  <p>
    <span class="basic-information website-name">TripMaster</span>
    is designed to be compatible with the following assistive technologies:
  </p>
  <ul class="technical-information compatible-environments">
    <li>Chrome</li>
    <li>Safari</li>
  </ul>
  <h2>Technical specifications</h2>
  <p>
    Accessibility of
    <span class="basic-information website-name">TripMaster</span>
    relies on the following technologies to work with the particular combination
    of web browser and any assistive technologies or plugins installed on your
    computer:
  </p>
  <ul class="technical-information technologies-used">
    <li>HTML</li>
    <li>WAI-ARIA</li>
    <li>CSS</li>
    <li>JavaScript</li>
  </ul>
  <p>
    These technologies are relied upon for conformance with the accessibility
    standards used.
  </p>
  <h2>Assessment approach</h2>
  <p>
    <span class="basic-information organization-name">CTS Software</span>
    assessed the accessibility of
    <span class="basic-information website-name">TripMaster</span>
    by the following approaches:
  </p>
  <ul class="technical-information assessment-approaches">
    <li>Self-evaluation</li>
  </ul>
  <hr />
  <p>
    This statement was created on
    <span class="basic-information statement-created-date">27 June 2019</span>
    using the
    <a
      rel="noopener"
      target="_blank"
      href="https://www.w3.org/WAI/planning/statements/"
      >W3C Accessibility Statement Generator Tool</a
    >.
  </p>
  <app-footer></app-footer>
</div>
