import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  private readonly router = inject(Router);
  private readonly auth = inject(AuthenticationService);

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.auth.currentUser$.pipe(
      map(user => {
        if (user) return true;
        if (state.url === '/login') { return false; }
        else return this.router.createUrlTree(['/login']);
      }),
      catchError((err) => {
        console.error(err);
        if (state.url === '/login') { return of(false); }
        return of(this.router.createUrlTree(['/login']));
      }),
    );
  }
}
