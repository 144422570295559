import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from "@angular/common/http";

import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from "@angular/common";
import { ErrorDisplayComponent } from "./components/error-display/error-display.component";
import { FooterComponent } from "./components/footer/footer.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { HeaderComponent } from "./components/header/header.component";
import { IonicModule } from "@ionic/angular";
import { MapComponent } from "./components/map/map.component";
import { MaterialModule } from "../material/material.module";
import { NgModule } from "@angular/core";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { PhonePipe } from "./pipes/phone.pipe";
import { RidePriorityTimePipe } from "./pipes/priorityTime.pipe";
import { RideComponent } from "./components/ride/ride.component";
import { RouterModule } from "@angular/router";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { StepsComponent } from "./components/cdk-steps/cdk-steps.component";
import { StaxComponent } from "./components/stax/stax.component";

@NgModule({
  declarations: [
    //  Components
    StepsComponent,
    RideComponent,
    FooterComponent,
    HeaderComponent,
    ErrorDisplayComponent,
    NotFoundComponent,
    MapComponent,
    StaxComponent,
    //  Pipes
    PhonePipe,
    RidePriorityTimePipe,
    // Directives
  ],
  exports: [
    //  Modules
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    ScrollingModule,
    GoogleMapsModule,
    //  Components
    CdkStepperModule,
    StepsComponent,
    RideComponent,
    FooterComponent,
    HeaderComponent,
    NotFoundComponent,
    MapComponent,
    StaxComponent,
    //  Pipes
    PhonePipe,
    NgxMaskPipe,
    RidePriorityTimePipe,
    // Directives
    NgxMaskDirective,
  ],
  imports: [
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    ScrollingModule,
    GoogleMapsModule,
    //  Pipes
    NgxMaskPipe,
    // Directives
    NgxMaskDirective,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    provideNgxMask(),
  ],
})
export class SharedModule { }
