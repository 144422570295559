export const environment = {
  production: true,

  appName: 'Request My Ride',
  brandKey: 'cts',
  companyName: 'CTS Software',
  apiUrl: 'https://api.requestmyrides.com',
  ionicAppId: 'e521ca3c',
  appId: 'com.CTS.RiderPortalApp',

  maxLoginAttempts: 5,
  googleMapsAPI: 'AIzaSyCPFOyoWh0oS_YareBCdYP-lz4geB-M3iM',
  TrackJS: {
    token: '85bb494f73664aa19e6b1b6237d458a5',
    enabled: false,
    application: 'riderportalcore',
    console: { display: false },
  },
  keys: {
    currentUser: 'cts-riderportal-currentuser',
    email: 'cts-riderportal-email',
    portalSettings: 'cts-portalsettings',
    providerAssigned: 'cts-portal-provider-assigned',
  },

  features: {
    helpUrl: 'https://cts-software.zendesk.com/hc/en-us/categories/360002749633-Rider-Portal',
    showAccessibilityStatement: true,
  },
};
