import { AuthGuard } from './shared/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AccessibilityStatementComponent } from './accessibility-statement/accessibility-statement.component';
import { OutOfSupportComponent } from './out-of-support/out-of-support.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'index.html',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'accessibility-statement',
    component: AccessibilityStatementComponent,
  },
  {
    path: 'rides',
    loadChildren: () =>
      import('./rides/rides.module').then((m) => m.RidesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'payments',
    loadChildren: () =>
      import('./payments/payments.module').then((m) => m.PaymentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./select-ride/select-ride.module').then(
        (m) => m.SelectRideModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'rider-location',
    loadChildren: () =>
      import('./rider-location/rider-location.module').then(
        (m) => m.RiderLocationModule,
      ),
  },
  { path: 'out-of-support', component: OutOfSupportComponent },
  { path: '**', component: NotFoundComponent }, // MUST BE LAST!
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled' }),
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: ['indexeddb', 'sqlite', 'websql'],
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule { }
