import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  private _isLoaded$ = new BehaviorSubject<boolean>(false);
  public isLoaded$: Observable<boolean> = this._isLoaded$.asObservable();

  static DEFAULT_CENTER: google.maps.LatLngLiteral = {
    lat: 39.8283459,
    lng: -98.5794797,
  };
  static DEFAULT_ZOOM: number = 17;
  DEFAULT_OPTIONS: google.maps.MapOptions = {
    center: MapsService.DEFAULT_CENTER,
    zoom: MapsService.DEFAULT_ZOOM,
    disableDefaultUI: true,
    zoomControl: true,
    // mapTypeControl: true,
    mapTypeControlOptions: {
      position: 3,
    },
  };

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) {
    let fallbackLocation: google.maps.LatLngLiteral;
    const currentUser = this.authService.getCurrentUser();
    const transportationProvider = currentUser?.transportationProvider;
    if (transportationProvider?.defaultLatitude && transportationProvider?.defaultLongitude) {
      fallbackLocation = { lat: transportationProvider.defaultLatitude, lng: transportationProvider.defaultLongitude };
    } else {
      fallbackLocation = MapsService.DEFAULT_CENTER;
    }

    if (!currentUser?.isDelegateUser) {
      navigator.geolocation.getCurrentPosition(
        value => {
          this.DEFAULT_OPTIONS.center = {
            lat: value.coords.latitude,
            lng: value.coords.longitude,
          };
        },
        () => { this.DEFAULT_OPTIONS.center = fallbackLocation; },
      );
    } else {
      this.DEFAULT_OPTIONS.center = fallbackLocation;
    }
    this.loadMapsApi().subscribe();
  }

  /**
   * Calls the maps api to get it initialized and loaded
   * @returns Observable<boolean>
   */
  private loadMapsApi(): Observable<boolean> {
    return this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPI}&libraries=places`,
        'callback',
      )
      .pipe(
        map(() => true),
        tap((val) => this._isLoaded$.next(val)),
        catchError((error) => {
          console.debug('🐞', error);
          this._isLoaded$.next(false);
          return of(false);
        }),
        take(1),
      );
  }
}
