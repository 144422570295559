import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _snackBar: MatSnackBar) { }

  success(message: string, action?: string, duration: number = 3000) {
    this._snackBar.open(message, action, { duration: duration, panelClass: ['snackbar-success-notification'] });
  }

  error(message: string, action = 'ok', duration: number = undefined) {
    this._snackBar.open('Error: ' + message, action, { duration: duration, panelClass: ['snackbar-error-notification'], verticalPosition: 'top' });
  }

  warning(message: string, action?: string, duration: number = 3000) {
    this._snackBar.open('Warning: ' + message, action, { duration: duration, panelClass: ['snackbar-warning-notification'] });
  }
}
