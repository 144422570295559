
const STATES = [
  { code: 'AL', name: 'Alabama', isTerritory: false },
  { code: 'AK', name: 'Alaska', isTerritory: false },
  { code: 'AS', name: 'American Samoa', isTerritory: true },
  { code: 'AZ', name: 'Arizona', isTerritory: false },
  { code: 'AR', name: 'Arkansas', isTerritory: false },
  { code: 'CA', name: 'California', isTerritory: false },
  { code: 'CO', name: 'Colorado', isTerritory: false },
  { code: 'CT', name: 'Connecticut', isTerritory: false },
  { code: 'DE', name: 'Delaware', isTerritory: false },
  { code: 'DC', name: 'District Of Columbia', isTerritory: true },
  { code: 'FM', name: 'Federated States Of Micronesia', isTerritory: true },
  { code: 'FL', name: 'Florida', isTerritory: false },
  { code: 'GA', name: 'Georgia', isTerritory: false },
  { code: 'GU', name: 'Guam', isTerritory: true },
  { code: 'HI', name: 'Hawaii', isTerritory: false },
  { code: 'ID', name: 'Idaho', isTerritory: false },
  { code: 'IL', name: 'Illinois', isTerritory: false },
  { code: 'IN', name: 'Indiana', isTerritory: false },
  { code: 'IA', name: 'Iowa', isTerritory: false },
  { code: 'KS', name: 'Kansas', isTerritory: false },
  { code: 'KY', name: 'Kentucky', isTerritory: false },
  { code: 'LA', name: 'Louisiana', isTerritory: false },
  { code: 'ME', name: 'Maine', isTerritory: false },
  { code: 'MH', name: 'Marshall Islands', isTerritory: true },
  { code: 'MD', name: 'Maryland', isTerritory: false },
  { code: 'MA', name: 'Massachusetts', isTerritory: false },
  { code: 'MI', name: 'Michigan', isTerritory: false },
  { code: 'MN', name: 'Minnesota', isTerritory: false },
  { code: 'MS', name: 'Mississippi', isTerritory: false },
  { code: 'MO', name: 'Missouri', isTerritory: false },
  { code: 'MT', name: 'Montana', isTerritory: false },
  { code: 'NE', name: 'Nebraska', isTerritory: false },
  { code: 'NV', name: 'Nevada', isTerritory: false },
  { code: 'NH', name: 'New Hampshire', isTerritory: false },
  { code: 'NJ', name: 'New Jersey', isTerritory: false },
  { code: 'NM', name: 'New Mexico', isTerritory: false },
  { code: 'NY', name: 'New York', isTerritory: false },
  { code: 'NC', name: 'North Carolina', isTerritory: false },
  { code: 'ND', name: 'North Dakota', isTerritory: false },
  { code: 'MP', name: 'Northern Mariana Islands', isTerritory: true },
  { code: 'OH', name: 'Ohio', isTerritory: false },
  { code: 'OK', name: 'Oklahoma', isTerritory: false },
  { code: 'OR', name: 'Oregon', isTerritory: false },
  { code: 'PW', name: 'Palau', isTerritory: true },
  { code: 'PA', name: 'Pennsylvania', isTerritory: false },
  { code: 'PR', name: 'Puerto Rico', isTerritory: false },
  { code: 'RI', name: 'Rhode Island', isTerritory: false },
  { code: 'SC', name: 'South Carolina', isTerritory: false },
  { code: 'SD', name: 'South Dakota', isTerritory: false },
  { code: 'TN', name: 'Tennessee', isTerritory: false },
  { code: 'TX', name: 'Texas', isTerritory: false },
  { code: 'UT', name: 'Utah', isTerritory: false },
  { code: 'VT', name: 'Vermont', isTerritory: false },
  { code: 'VI', name: 'Virgin Islands', isTerritory: false },
  { code: 'VA', name: 'Virginia', isTerritory: false },
  { code: 'WA', name: 'Washington', isTerritory: false },
  { code: 'WV', name: 'West Virginia', isTerritory: false },
  { code: 'WI', name: 'Wisconsin', isTerritory: false },
  { code: 'WY', name: 'Wyomin', isTerritory: false },
];
export const States = STATES.filter(s => !s.isTerritory);
export const StatesAndTerritories = [...STATES];
export const Territories = STATES.filter(s => s.isTerritory);
