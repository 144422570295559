import { Capacitor } from "@capacitor/core";
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { ISnapshotInfo } from "cordova-plugin-ionic/dist/ngx/IonicCordova";
import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class SnapshotManagementService {
  public get isWebPlatform() {
    return Capacitor.getPlatform() === "web";
  }

  constructor(
    private deploy: Deploy,
    private platform: Platform,
  ) { }

  public async configure(config: Pro_CurrentConfig): Promise<void> {
    console.log('🔍 Configuring...');
    if (this.isWebPlatform) return Promise.resolve();
    return this.deploy.configure({
      channel: config.channel,
      appId: config.appId,
    });
  }

  public async getConfiguration(): Promise<Pro_CurrentConfig> {
    console.debug('🐛 Getting new configurations...');
    if (this.isWebPlatform) return { appId: "0", channel: "NOT APPLICABLE" };
    return this.deploy.getConfiguration();
  }

  public getCurrentVersion(): Promise<Pro_SnapshotInfo | undefined> {
    if (this.isWebPlatform) return Promise.resolve(undefined);
    return this.deploy.getCurrentVersion();
  }

  public async getAvailableVersions(): Promise<Pro_SnapshotInfo[]> {
    if (this.isWebPlatform) return [];
    const versions = await this.deploy.getAvailableVersions();
    return versions.map(ss => this.mapSnapshotInfo(ss)).filter(x => x !== null && x !== undefined) as Pro_SnapshotInfo[];
  }

  public async checkForUpdate(): Promise<Pro_UpdateResponse> {
    if (this.isWebPlatform) return { available: false };
    const rsp = await this.deploy.checkForUpdate();
    return {
      available: rsp.available,
      buildId: rsp.build,
      snapshotId: rsp.snapshot,
      incompatibleUpdateAvailable: rsp.incompatibleUpdateAvailable,
      url: rsp.url,
    };
  }

  public async sync(
    method?: "background" | "auto",
    progressCallback?: (pct?: number) => void,
  ): Promise<Pro_SnapshotInfo | undefined> {

    if (this.isWebPlatform) return undefined;
    const syncResponse = await this.deploy.sync({ updateMethod: method }, progressCallback);
    return this.mapSnapshotInfo(syncResponse);
  }

  private mapSnapshotInfo(snapshot?: ISnapshotInfo | undefined): Pro_SnapshotInfo | undefined {
    return snapshot
      ? {
        channel: snapshot.channel,
        buildId: snapshot.buildId,
        versionId: snapshot.versionId,
        binaryVersionCode: snapshot.binaryVersionCode,
        binaryVersionName: snapshot.binaryVersionName,
      }
      : undefined;
  }
}

export interface Pro_CurrentConfig {
  /**
   * The [Ionic Pro](https://ionicframework.com/docs/pro/) app id.
   */
  appId: string;
  /**
   * The [channel](https://ionicframework.com/docs/pro/deploy/channels) that the plugin should listen for updates on.
   */
  channel: string;
  /**
   * The binary version of the native bundle versionName on Android or CFBundleShortVersionString on iOS
   */
  binaryVersionName?: string;
  /**
   * The build version code of the native bundle versionCode on Android or CFBundleVersion on iOS
   */
  binaryVersionCode?: string;
  /**
   * Whether the user disabled deploy updates or not.
   */
  disabled?: boolean;
  /**
   * The host API the plugin is configured to check for updates from.
   */
  host?: string;
  /**
   * The currently configured updateMethod for the plugin.
   */
  updateMethod?: 'none' | 'auto' | 'background';
  /**
   * The id of the currently applied updated or undefined if none is applied.
   */
  currentVersionId?: string;
  /**
   * The id of the currently applied build or undefined if none is applied.
   */
  currentBuildId?: string;
}

export interface Pro_SnapshotInfo {
  /**
   * The id for the snapshot.
   */
  versionId: string;
  /**
   * The id for the snapshot.
   */
  buildId: string;
  /**
   * The channel that the snapshot was downloaded for..
   */
  channel: string;

  /**
   * The binary version name the snapshot was downloaded for.
   * The versionName on Android or CFBundleShortVersionString on iOS this is the end user readable version listed on the stores.
   */
  binaryVersionName: string;
  /**
   * The binary version build code the snapshot was downloaded for.
   * The versionCode on Android or CFBundleVersion on iOS this should be changed every time you do a new build debug or otherwise.
   */
  binaryVersionCode: string;
}

export interface Pro_UpdateResponse {
  /**
   * Whether or not an update is available.
   */
  available: boolean;
  /**
   * The id of the snapshot if available.
   */
  snapshotId?: string;
  /**
   * The id of the build if available.
   */
  buildId?: string;
  /**
   * The url to fetch the manifest of files in the update.
   */
  url?: string;
  /**
   * Whether or not there is an update available that is not compatible with this device.
   */
  incompatibleUpdateAvailable?: boolean;
}
