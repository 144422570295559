import { Component } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-cdk-steps',
  template:
    '<ng-container [ngTemplateOutlet]="selected?.content"></ng-container>',
  providers: [{ provide: CdkStepper, useExisting: StepsComponent }],
})
export class StepsComponent extends CdkStepper {}
