import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorDisplayComponent } from '../components/error-display/error-display.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private snackBar: MatSnackBar) { }

  showGlobalErrorMessage(errorMessage?: string) {
    console.debug('🐞', 'error message: ', errorMessage);
    this.snackBar.openFromComponent(ErrorDisplayComponent, {
      panelClass: 'snackbar-error',
      data: errorMessage,
    });
  }
}
