import 'zone.js';

import { ConstructStartupUrl, STORAGE_KEY } from './shared/services/settings.service';
import { EnvironmentInjector, ErrorHandler, NgModule } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { API_BASE_URL } from './shared/services/nswag/service-proxies';
import { AccessibilityStatementComponent } from './accessibility-statement/accessibility-statement.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AuthInterceptor } from './shared/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { GlobalErrorHandler } from './shared/global-error.handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { OutOfSupportComponent } from './out-of-support/out-of-support.component';

export function factory() {
  return localStorage.environment.apiUrl;
}

export function initialConfig() {
  const url = ConstructStartupUrl(STORAGE_KEY);
  environment.apiUrl = url;
  return url;
}

@NgModule({
  declarations: [
    AppComponent,
    AccessibilityStatementComponent,
    OutOfSupportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  exports: [],
  providers: [
    { provide: API_BASE_URL, useFactory: initialConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    Deploy,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
