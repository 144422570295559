import { Pro_CurrentConfig, Pro_SnapshotInfo, SnapshotManagementService } from 'src/app/select-ride/services/snapshot-management.service';

import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { LoggingService } from './logging.service';
import { NotificationService } from './notification.service';
import { buildInfo } from '../../config/build-info';
import { BehaviorSubject, Observable } from 'rxjs';
import packageJson from 'package.json';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  // This is where we need to put the current application software version.
  public static SoftwareVersion = packageJson.version;
  public versionOutOfDate: boolean;

  protected _binaryOutOfDate = false;
  private readonly _binaryOutOfDate$ = new BehaviorSubject<boolean>(this._binaryOutOfDate);
  public readonly binaryOutOfDate$: Observable<boolean> = this._binaryOutOfDate$.asObservable();
  get binaryOutOfDate(): boolean { return this._binaryOutOfDate; }
  set binaryOutOfDate(outOfDate: boolean) {
    this._binaryOutOfDate = outOfDate;
    this._binaryOutOfDate$.next(outOfDate);
  }

  // this may go elsewhere but need to display on ui for now
  snapshotInfo: Pro_SnapshotInfo = null;
  public readonly snapshotInfo$ = new BehaviorSubject<Pro_SnapshotInfo | null>(null);
  updateAvailable: boolean = false;

  // putting this here since it's a singleton and will persist for life of app instance
  showPlayStoreUpgradeButton = false;
  upgradeMessage = '';
  forceUpdateBeforeLogin = false;
  currentChannel: string = '';
  configuration?: Pro_CurrentConfig;

  constructor(
    private snapshotService: SnapshotManagementService,
    private loggingService: LoggingService,
    public notificationService: NotificationService,
    public loadingCtrl: LoadingController,
    public platform: Platform,
  ) {
  }

  get snapshotVersion(): string {
    return this.snapshotInfo ? this.snapshotInfo.versionId : (this.configuration?.currentVersionId || 'None');
  }
  get snapshotBuildId(): string {
    return this.snapshotInfo ? this.snapshotInfo.buildId : (this.configuration?.currentBuildId || 'Unknown');
  }
  get snapshotBinaryVersionName(): string {
    return this.snapshotInfo ? this.snapshotInfo.binaryVersionName : (this.configuration?.binaryVersionName || 'Unknown');
  }
  get buildVersion(): string {
    return buildInfo.publicVersion;
  }
  get buildDate(): string {
    return buildInfo.date;
  }

  // this always gets called after we register our device on the server and know what channel it should point to
  setChannel(channel: string) {
    if (this.currentChannel != channel) {
      this.currentChannel = channel;
      this.configuration = undefined;   // clear out the configuration
      this.snapshotInfo = undefined;    // clear out the configuration
      this.snapshotInfo$.next(null);
      this.updateAvailable = false;     // clear out the configuration
    }
  }

  // this always gets called after we register our device on the server and know if the Server votes that the app is out of date
  setVersionOutOfDate(versionOutOfDate: boolean) {
    this.versionOutOfDate = versionOutOfDate;

    if (this.versionOutOfDate) {
      this.checkForNewVersion(this.currentChannel);
    }
  }

  async verifyConfiguration(): Promise<boolean> {
    console.debug('🐛', 'Verifying configurations...', this.configuration);
    if (this.currentChannel && !this.configuration) {
        try {
          const configuration = await this.snapshotService.configure({ appId: buildInfo.ionicAppId, channel: this.currentChannel });
          this.configuration = await this.snapshotService.getConfiguration();
          console.debug('🐛', 'new configurations', this.configuration);
        } catch (err) {
          console.error(err);
          return false;
        }
    }
    return true;
  }

  async checkSnapshotVersion(forceCheck: boolean = true) {
    if (Capacitor.isNativePlatform() && (!this.snapshotInfo || forceCheck)) {
      try {
        if (await this.verifyConfiguration()) {
          this.snapshotInfo = await this.snapshotService.getCurrentVersion();
          this.snapshotInfo$.next(this.snapshotInfo);
          this.updateAvailable = !!(await this.snapshotService.checkForUpdate())?.available;
          console.debug('🐛', `Current snapshot version found:`, this.snapshotInfo);
        }
      } catch (err) {
        console.error('error occurred getting current snapshot and checking for updates.', err);
      }
    }
  }

  async checkForNewVersion(channel?: string) {
    // return if not on device
    if (!Capacitor.isNativePlatform()) return;

    if (channel && (channel == 'configurable' || channel == '')) {
      console.warn(`You cannot update unless the channel has been defined.`);
      return;
    }

    // set the current channel
    this.setChannel(channel || this.currentChannel);

    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Checking for updates...',
    });

    try {
      await loading.present();

      // this function makes sure we're all configured and stuff.
      await this.checkSnapshotVersion();

      const versionId = this.snapshotVersion;

      if (this.updateAvailable) {
        console.log('🔍', 'Checking for updates...');
        loading.message = 'Checking for updates...';

        const progress = (percentage: number) => {
          loading.message = `Applying updates (${percentage}%). App will restart when finished`;
        };
        const resp = await this.snapshotService.sync('auto', progress);
        console.debug('🐛 Sync response:', resp);
        console.debug('🐛 Current versionId:', versionId);

        if (resp && versionId !== resp.versionId) {
          console.log('🔍 Updating...');
          this.snapshotInfo = resp;
          this.snapshotInfo$.next(resp);
        } else {
          console.log('🔍 Software is up to date');
          loading.message = `Software is up to date`;
          loading.dismiss();
        }
      } else {
        console.log('🔍 Software is up to date');
        loading.message = `Software is up to date`;
        loading.dismiss();
      }
    } catch (err) {
      console.error('error applying snapshot', err);
      this.notificationService.warning(
        'There was an error downloading updates, please contact support.',
      );
    } finally {
      loading.dismiss();
    }
  }
}
