import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
// import { LoggingService } from "./logging.service";

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    protected storage: Storage, // protected loggingService: LoggingService
  ) {}

  public async set(key: string, value: any): Promise<void> {
    if (this._storage == null) {
      this._storage = await this.storage.create();
    }
    await this._storage.set(key, value);
    // this.loggingService.log("storage save", value);
  }

  public async get(key: string): Promise<any> {
    if (this._storage == null) {
      this._storage = await this.storage.create();
    }
    return this._storage.get(key);
  }

  public async remove(key: string): Promise<void> {
    if (this._storage == null) {
      this._storage = await this.storage.create();
    }
    await this._storage.remove(key);
    // this.loggingService.log("storage remove: ", key);
  }
}
