import { Injectable } from "@angular/core";

import { login } from './data/login';
import { portalSettings } from './data/portal-settings';
import { HttpRequest } from "@angular/common/http";
import { DateTime } from "luxon";
import { RequestRideResponse, RideModel } from "../nswag/service-proxies";

@Injectable({ providedIn: 'root' })
export class MockService {

  isLoggedIn = false;
  private readonly channelInfo =  { "storeUpdateRequired":false, "channel":"TripPortal.Production" };
  private futureRides: RequestRideResponse[] = [];

  get(request: HttpRequest<any>) {
    const url = request.url;
    const body = request.body ? JSON.parse(request.body) : null;

    let response: any = undefined;
    if (/Device\/channel-info/.test(url)) { response = this.channelInfo; }

    if (/Authentication\/login/.test(url)) {
      this.isLoggedIn = true;
      response = login;
    }
    if (/Authentication\/login-check/.test(url)) { response = this.isLoggedIn; }
    if (/Authentication\/logout/.test(url)) { response = true; }

    if (/Rider\/portal-settings/.test(url)) { response = portalSettings; }
    if (/Rider\/list-rider-ride-requests/.test(url)) {
      response = body.pastRides === false
      ? { requestedRides: this.futureRides, totalRecords: this.futureRides.length, pageSize:100 }
      : { requestedRides:[], totalRecords: 0, pageSize:100 };
    }
    if (/Rider\/list-requests-pending-payment/.test(url)) { response = { requestedRides:[], totalRecords: 0, pageSize:100 }; }

    if (/RiderAddress\/address-history/.test(url)) { response = []; }

    //  get Fare information
    if (/Rider\/testAccount\/fare\/1/.test(url)) {
      response = body.map(request => {
        const distance = this.crowFliesDistance(
          request.pickupAddress.latitude,
          request.pickupAddress.longitude,
          request.dropoffAddress.latitude,
          request.dropoffAddress.longitude,
        );
        const minutes = isNaN(distance) ? 0 : distance * 45;
        const duration = DateTime.now().startOf("year").plus({ minutes }).toFormat('HH:mm:ss.SSS');
        return { request, fare: this.calcFare(distance), distance, duration };
      });
    }

    if (/Rider\/request-rides/.test(url)) { response = body.rideFares.map(ride => {
        const response = this.buildRideRequestResponse(ride.rideRequest);
        this.futureRides.push(response);
        return response;
      });
    }

    if (/Rider\/cancel-request-ride/.test(url)) {
      const rideId = url.match(/\/(?<rideId>[^\/]*?)$/).groups?.rideId;
      const ride = this.futureRides.find(ride => ride.responseDetails.rideDetails.ride.rideId === rideId);
      if (ride) { ride.responseDetails.rideDetails.ride.rideStatusId = 3; }
      response = { responseMessage:"Cancelled Successfully", cancelled:true };
    }

    return {
      success: response !== undefined,
      response,
    };
  }

  private calcFare(distance: number) {
    return distance * 1.25;
  }

  private crowFliesDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    if (!lat1 || !lon1 || !lat2 ||!lon2) { return 0; }
    const r = 6371; // km
    const p = Math.PI / 180;

    const a = 0.5 - Math.cos((lat2 - lat1) * p) / 2
                  + Math.cos(lat1 * p) * Math.cos(lat2 * p) *
                    (1 - Math.cos((lon2 - lon1) * p)) / 2;

    const distanceInKm = 2 * r * Math.asin(Math.sqrt(a));
    const miles = distanceInKm / 1.609;
    const distanceInMiles = Number(miles.toLocaleString(undefined, { maximumFractionDigits: 2 }).replace(/,/g, ''));
    return distanceInMiles;
  }

  private buildRideRequestResponse(ride: RideModel): any  {
    const distance = this.crowFliesDistance(
      ride.pickupAddress.latitude,
      ride.pickupAddress.longitude,
      ride.dropoffAddress.latitude,
      ride.dropoffAddress.longitude,
    );
    const transportationProvider = login.userProfile.transportationProviders[0];
    return {
      responseDetails: {
        rideRequestResponseCode: 2,
        initialAcceptStatus: null,
        initialAcceptMessage: null,
        name: '',
        rideDetails: {
          driver: null,
          vehicle: null,
          scheduledPickupTime: new Date('0001-01-01T00:00:00'),
          ride: {
            ...ride,
            rideId: Date.now().toString(),
            rideStatusId: 1,
            transportationProviderId: transportationProvider.transportationProviderId,
            vendorRiderId: login.userProfile.portalUserId,
            riderName: `${login.userProfile.firstName} ${login.userProfile.lastName} `,
            portalUserId: login.userProfile.portalUserId,
            pickupAddress: { ...ride.pickupAddress, name: '', addressId: Date.now().toString(), phone: null, isFavorite: false },
            dropoffAddress: { ...ride.dropoffAddress, name: '', addressId: Date.now().toString(), phone: null, isFavorite: false },
          },
          fareAmount: null,
        },
        reason: "Ride is outside service area",
        scheduledPickupTimeUTC: null,
        fareAmount: this.calcFare(distance),
      },
      transportationProvider,
    };
  }

}
