import { ErrorHandler, Injectable } from '@angular/core';

import { ErrorService } from './services/error.service';
import { LoggingService } from './services/logging.service';
import { TrackJS } from 'trackjs';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private errorSvc: ErrorService,
    private loggerService: LoggingService,
  ) { }

  handleError(error: any) {
    // This can have useful content for TrackJS (and our developers)
    console.warn(error.message, error);
    this.loggerService.logFatal(error.message, error);

    // Send the native error object to TrackJS
    TrackJS.track(error.originalError || error);
  }
}
