<div class="ride-request">
  <div class="body">
    <div class="address pickup">
      <span>{{ ride.pickupAddress?.address1 }}</span>
      <span *ngIf="ride.pickupAddress?.address2">
        {{ ride.pickupAddress?.address2 }}
      </span>
      <span>
        {{ ride.pickupAddress?.city }}
        {{ ride.pickupAddress?.state }}
        {{ ride.pickupAddress?.zip }}
      </span>
    </div>

    <img src="/assets/icons/arrow-right.svg" alt="" width="32" height="32">

    <div class="address dropoff">
      <span>{{ ride.dropoffAddress?.address1 }}</span>
      <span *ngIf="ride.dropoffAddress?.address2">{{
        ride.dropoffAddress?.address2
        }}</span>
      <span>{{ ride.dropoffAddress?.city }} {{ ride.dropoffAddress?.state }}
        {{ ride.dropoffAddress?.zip }}</span>
    </div>

    <div class="time" [ngClass]="findTimeClass(ride.reservationTypeDetails?.name)">
      <span class="label">
        <mat-icon *ngIf="ride.reservationTypeDetails?.name === 'Pickup'">arrow_upward_alt</mat-icon>
        <mat-icon *ngIf="ride.reservationTypeDetails?.name === 'Dropoff'">arrow_downward_alt</mat-icon>
        {{ ride.reservationTypeDetails?.name }}:
      </span>
      <span *ngIf="ride.time">: {{ ride.time }}</span>
    </div>

    <dl>
      <div *ngIf="ride.fareResponseItem?.distance" class="distance">
        <dt><mat-icon>directions</mat-icon> Distance:</dt>
        <dd>{{ ride.fareResponseItem?.distance | number: '1.2-2' }} miles</dd>
      </div>

      <div *ngIf="ride.fareResponseItem?.duration" class="duration">
        <dt><mat-icon>schedule</mat-icon> Duration:</dt>
        <dd>{{ formatDuration(ride.fareResponseItem?.duration.toString()) }}</dd>
      </div>

      <div class="escorts">
        <dt><mat-icon>group</mat-icon> Escorts:</dt>
        <dd>{{ ride.escorts || 0 }}</dd>
      </div>

      <div class="mobility">
        <dt>
          <mat-icon *ngIf="ride.wheelchairRequired">accessible</mat-icon>
          <mat-icon *ngIf="ride.stretcherRequired">airline_seat_flat</mat-icon>
          <mat-icon *ngIf="!ride.wheelchairRequired && !ride.stretcherRequired">directions_walk</mat-icon>
          Mobility:
        </dt>
        <div class="mobility">
          <dd *ngIf="ride.wheelchairRequired">Wheelchair</dd>
          <dd *ngIf="ride.stretcherRequired">Stretcher</dd>
          <dd *ngIf="!ride.stretcherRequired && !ride.wheelchairRequired">None</dd>
        </div>
      </div>

      <div class="fair" *ngIf="ride.fareResponseItem?.fareAmount">
        <dt><mat-icon>payments</mat-icon> Fare:</dt>
        <dd>{{ ride.fareResponseItem?.fareAmount | currency }}</dd>
      </div>

      <div class="comments" *ngIf="ride.comments">
        <dt><mat-icon svgIcon="comments"></mat-icon> Comments:</dt>
        <dd>{{ ride.comments }}</dd>
      </div>
    </dl>
  </div>
</div>
