export const portalSettings = {
  "companyName": "Cooper's Company",
  "phoneNumber": "1234567890",
  "companyLogoUrl": null,
  "defaultAccountId": 20,
  "defaultAccountDisplayValue": "PP",
  "defaultAccountDisplayName": "Private Pay",
  "allowCancellations": true,
  "allowRideRequestsFromSelfRegisteredRiders": true,
  "denyBasedOnMinDaysBeforeRideDate": null,
  "maximumNumberOfDaysInTheFuture": null,
  "denyBasedOnOutOfServiceDate": false,
  "denyBasedOnRouteAvailability": false,
  "acceptBasedOnScheduling": true,
  "denyBasedOnScheduling": false,
  "minimumNumberOfHoursBeforePriorityTime": 2,
  "denyIfRiderIsInactive": false,
  "denyIfRideIsSoonerThanPriorityTimeMinimumHours": true,
  "allowRiderToPayForRides": false,
  "requirePaymentForRide": false,
  "allowRiderToPutMoneyOnPasses": false,
  "denyIfRideIsTooFarInTheFuture": false,
  "transportationProviderResourceId": "1",
  "outOfServiceDates": [],
  "paymentProvider": null,
};
