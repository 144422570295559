import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

import { HelperService } from '../../services/helper.service';
import { RequestBuilderService } from '../../../select-ride/services/request-builder.service';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  destroyed$: Subject<boolean> = new Subject();
  hideBackBtn: boolean = false;
  back: { path: string[]; text: string };
  previousUrl: string;
  queryParams: Params;
  private hideBackBtnUrls: string[] = ['/rides/future', '/rides', '/login', '/logout', '/', '/out-of-support'];
  returnUrls = {
    'accessibility-statement': { path: ['/rides'], text: 'back' },
    'recover-account': { path: ['/login'], text: 'back' },
    'accept-invitation/agreement/rider': { path: ['/accept-invitation'], text: 'back' },
    'verify-email/agreement': { path: ['/verify-email'], text: 'back' },
    'rides/future': { path: ['/rides/future'], text: 'back' },
    'payments': { path: ['/rides'], text: 'back' },
    'create-account': { path: ['/create-account'], text: 'back' },
    agreement: { text: 'back' },

    'request-ride': { path: ['/rides'], text: 'cancel' },
    'create-rider': { path: ['/request-ride'], text: 'cancel' },
    'ride/edit': { path: ['/rides'], text: 'cancel' },
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private requestBuilder: RequestBuilderService,
    private authSvc: AuthenticationService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        tap((event: NavigationEnd) => {
          this.back = this.getBackValue(event.url);
          this.hideBackBtn = this.hideBackButton(event.url);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.queryParams = this.route.snapshot.queryParams;
      });
  }

  getBackValue(url: string): { path: string[]; text: string } {
    for (const key in this.returnUrls) {
      if (url.includes(key) && key === 'create-rider') return this.returnUrls[key];
      if (url.includes(key) ) {
        this.returnUrls[key].path = this.previousUrl || this.returnUrls[key].path;
        return this.returnUrls[key];
      }
    }
    HelperService.updateUrl(this.previousUrl);
    if (/login/.test(url)) { url = '/login'; }
    this.previousUrl = url;
    return { path: ['rides'], text: 'back' };
  }

  public hideBackButton(url: string): boolean {
    // Remove the query string part of the url, if there is one
    const queryStartIndex = url.indexOf('?');
    const rootPage = queryStartIndex === -1 ? url : url.slice(0, queryStartIndex);

    this.hideBackBtn = this.hideBackBtnUrls.includes(rootPage);

    return this.hideBackBtn;
  }

  cancel() {
    this.requestBuilder.reset();
    if (!this.authSvc.getIsProviderAssigned()) {
      sessionStorage.removeItem(environment.keys.portalSettings);
      console.log('🔍 Portal settings cleared');
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
