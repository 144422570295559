import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { EMPTY, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication.service';
import { DateTime } from 'luxon';
import { States } from '../../models/states.model';

declare const StaxJs: any;

@Component({
  selector: 'app-stax',
  templateUrl: './stax.component.html',
  styleUrls: ['./stax.component.scss'],
})
export class StaxComponent implements OnInit, AfterViewInit, OnDestroy {

  // private readonly SOURCE = 'https://staxjs.staxpayments.com/stax.js';
  private readonly destroyed$ = new Subject<boolean>();

  public readonly monthRegex = /^(0\d)|(1[0-2])$/;
  public readonly zipRegex = /^\d{5}$/;
  public readonly minYear = DateTime.now().year;
  public readonly maxYear = DateTime.now().year + 1000;
  public readonly states = States;
  validNumber = false;
  validCvv = false;
  private reload$ = new Subject<boolean>();
  private retryCount = 0;
  public crashed = false;
  private unhandledrejectionListener: () => void;

  public form = new FormGroup({
    firstname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    lastname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    phone: new FormControl('', [Validators.required, Validators.minLength(10)]),
    address_1: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    address_2: new FormControl('', [Validators.maxLength(255)]),
    address_city: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    address_state: new FormControl('', [Validators.required, Validators.maxLength(2)]),
    address_zip: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.pattern(this.zipRegex)]),
    method: new FormControl('card', [Validators.required]),
    month: new FormControl('', [Validators.required, Validators.pattern(this.monthRegex)]),
    year: new FormControl('', [Validators.required, Validators.min(this.minYear), Validators.max(this.maxYear)]),
  });

  private stax: any;
  /** Stax field validation listeners */
  public staxInvalid = true;
  public loaded = false;
  public readonly providerToken$ = new ReplaySubject<string>(1);

  @Input() selectedRides: string[] = [];
  @Input() total: number = 0;
  @Input() showTitle: boolean = true;
  @Output() valid = new EventEmitter<boolean>();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authService: AuthenticationService,
    private readonly ngZones: NgZone,
    private readonly renderer: Renderer2,
  ) {

    const currentUser = this.authService.getCurrentUser();
    this.form.patchValue({
      firstname: currentUser.firstName,
      lastname: currentUser.lastName,
      phone: currentUser.phoneNumber,
      address_1: currentUser.address?.address1,
      address_2: currentUser.address?.address2,
      address_city: currentUser.address?.city,
      address_state: currentUser.address?.state,
      address_zip: currentUser.address?.zip,
    });
    this.unhandledrejectionListener = this.renderer.listen(window, 'unhandledrejection', (event) => {
      this.handleError(event.reason);
    });
  }

  ngOnInit(): void {

    this.form.valueChanges.pipe(
      tap(() => {
        this.valid.emit(this.validCvv && this.validNumber && this.form.valid);
      }),
      takeUntil(this.destroyed$),
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this.ngZones.runGuarded(() => {
      combineLatest([
        this.providerToken$.asObservable(),
        this.reload$.pipe(startWith(true)),
      ]).pipe(
        switchMap(([token]) => {
          this.loaded = false;
          if (!token) { return EMPTY; }
          this.loaded = true;
          try {
            this.stax = new StaxJs(token, {
              number: {
                id: 'card-number',     // the html id of the div you want to contain the credit card number field
                placeholder: '0000 0000 0000 0000',    // the placeholder the field should contain
                style: 'height: 32px; width: 100%; font-size: 16px;',    // the style to apply to the field
                type: 'text',    // the input type (optional)
                format: 'prettyFormat',   // the formatting of the CC number (prettyFormat || plainFormat || maskedFormat)
              },
              cvv: {
                id: 'card-cvv',    // the html id of the div you want to contain the cvv field
                placeholder: 'CVV',    // the placeholder the field should contain
                style: 'height: 32px; width: 100%; font-size: 16px;',    // the style to apply to the field
                type: 'text',    // the input type (optional)
              },
            });
          } catch (err) {
            console.error(err);
          }
          //  Incomplete listener, triggers on form changes
          this.stax.on('card_form_incomplete', ({ validNumber, validCvv }) => {
            this.validCvv = validCvv;
            this.validNumber = validNumber;
            this.changeDetectorRef.detectChanges();
            this.valid.emit(this.validCvv && this.validNumber && this.form.valid);
          });

          //  Complete listener, triggers on form changes
          this.stax.on('card_form_complete', ({ validNumber, validCvv }) => {
            this.validCvv = validCvv;
            this.validNumber = validNumber;
            this.changeDetectorRef.detectChanges();
            this.valid.emit(this.validCvv && this.validNumber && this.form.valid);
          });

          // Display stax fields (card number and vcc)
          // Look...I know it's weird, but it works. Something within the Stax/Spreedly code
          // is not happy unless we add the call to the end the browswer queue
          // there's probably a better way to do this but I can't figure it out
          return of(setTimeout(() => this.stax.showCardForm(), 0));
        }),
        catchError(err => {
          console.error('stax failed to load', err);
          return EMPTY;
        }),
        takeUntil(this.destroyed$),
      ).subscribe({
        next: (handler: any) => {
          // for quick testing, you can set a test number and test cvv here
          // handler.setTestPan("4111111111111111");
          // handler.setTestCvv("123");
          console.log('stax ready');
        },
        error: err => console.error(err),
      });
    });

    this.ngZones.onError.asObservable().subscribe(event => {
      const status = event.message.match(/"status"\:(\d{3})/)?.[1];
      const message = event.message.match(/"message"\:.*?"(.*?)"/)?.[1];
      this.handleError({ ...event, status, message });
    });
  }

  handleError(err: any): void {
    if (err.status == 504 && this.retryCount < 4) {
      this.retryCount++;
      this.stax = null;
      this.reload$.next(true);
    } else {
      //  It done died, we tried 3 times and we are giving up
      this.crashed = true;
    }
    this.loaded = false;
  }

  /** Load the script from CDN */
  //  TODO: Investigate how to not put the JS script in the HTML and load it only for users that need it
  //  Currently code is being called from index.html
  // private loadScript(): void {
  //   const isLoaded = !!document.querySelector(`script[src='${this.SOURCE}']`);
  //   if (isLoaded) { return; }

  //   let node = document.createElement('script');
  //   node.src = this.SOURCE;
  //   node.async = false;
  //   document.querySelector('body').appendChild(node);
  // }

  /** Pay - not currently needed */
  // public async pay(): Promise<void> {
  //   console.log('Pay');
  //   if (this.form.invalid || !this.validNumber || !this.validCvv) { return; }
  //   const extraDetails = this.form.value;
  //   try {
  //     const response = await this.stax.pay(extraDetails);
  //     console.log(response);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  /** Get Token */
  public async tokenize(): Promise<object> {
    if (this.form.invalid || !this.validNumber || !this.validCvv) { return null; }
    const extraDetails = this.form.value;
    return this.stax.tokenize(extraDetails);
  }

  /** For display, adds asterisk on required fields */
  public required(formControlName: string): boolean {
    return !!this.form.get(formControlName)?.hasValidator(Validators.required);
  }

  /** For display, determines whether to show field validation errors */
  public getError(formControlName: string, error: string = undefined): boolean {
    const control = this.form.get(formControlName);
    if (!control?.touched) { return false; }
    if (!error) { return control.invalid; }
    return !!control.getError(error);
  }

  reload(): void {
    location.reload();
  }

  ngOnDestroy(): void {
    this.unhandledrejectionListener();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

/**
 * Test Credit Cards Numbers
 *
 * Visa
 *    success 4111111111111111
 *    failure 4012888888881881
 *
 * MasterCard
 *    success 5555555555554444
 *    failure 5105105105105100
 *
 * American Express
 *    success 378282246310005
 *    failure 371449635398431
 *
 * Discover
 *    success 6011111111111117
 *    failure 6011000990139424
 */
