import { GenderType, RideRequirementType, RideReservationType, RideStatusType, ServiceProxy } from './nswag/service-proxies';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { STATUSES } from '../models/ride-status.model';

@Injectable({
  providedIn: 'root',
})
export class ValuesService {

  private genderTypes: Value<GenderType>;
  private rideRequirementTypes: Value<RideRequirementType>;
  private rideReservationTypes: Value<RideReservationType>;
  private rideStatusTypes: Value<RideStatusType>;

  constructor(
    private readonly proxies: ServiceProxy,
  ) {
    // load all lists for the first time
    this.getRideRequirementTypes().pipe(take(1)).subscribe();
    this.getRideReservationTypes().pipe(take(1)).subscribe();
    this.getRideStatusTypes().pipe(take(1)).subscribe();
    this.getGenderTypes().pipe(take(1)).subscribe();
  }

  private buildMap<Type>(list: any[], key: string): { [key: string]: Type } {
    return list.reduce((acc, item) => ({ ...acc, [item[key]]: item }), {});
  }

  private buildList<Type>(list: any[], name: string, fieldId: string): Value<Type> {
    return {
      list,
      maps: ['name', fieldId].reduce((acc, key) => {
        return { ...acc, [key]: this.buildMap(list, key === 'name' ? name : key) };
      }, {}),
      getById: (id: string) => list.find(item => item[fieldId] == id),
      getByName: (name: string) => list.find(item => item.name === name || item.title === name),
    };
  }

  /**
   * Memoized list of genders and maps by name and genderId.
   * Has optional boolean parameter to force a fresh pull.
   */
  getGenderTypes(force: boolean = false): Observable<Value<GenderType>> {
    if (this.genderTypes && !force) { return of(this.genderTypes); }
    return this.proxies.genderTypes().pipe(
      map(res => this.buildList<GenderType>(res, 'name', 'genderId')),
      tap(res => this.genderTypes = res),
    );
  }

  /**
   * Memoized list of ride requirements and maps by name and rideStatusId.
   * Has optional boolean parameter to force a fresh pull.
   */
  getRideRequirementTypes(force: boolean = false): Observable<Value<RideRequirementType>> {
    if (this.rideRequirementTypes && !force) { return of(this.rideRequirementTypes); }
    return this.proxies.rideRequirementTypes().pipe(
      map(res => this.buildList<RideRequirementType>(res, 'name', 'rideRequirementId')),
      tap(res => this.rideRequirementTypes = res),
    );
  }

  /**
   * Memoized list of reservation types and maps by name and rideReservationId.
   * Has optional boolean parameter to force a fresh pull.
   */
  getRideReservationTypes(force: boolean = false): Observable<Value<RideReservationType>> {
    if (this.rideReservationTypes && !force) { return of(this.rideReservationTypes); }
    return this.proxies.rideReservationTypes().pipe(
      map(res => this.buildList<RideReservationType>(res, 'name', 'rideReservationId')),
      tap(res => this.rideReservationTypes = res),
    );
  }

  //  TODO: data needs fixed to use api instead of hard coded version
  /**
   * Memoized list of ride statuses and maps by name and rideStatusId
   * Has optional boolean parameter to force a fresh pull.
   */
  getRideStatusTypes(force = false): Observable<Value<RideStatusType>> {
    //   if (this.rideStatusTypes && !force) { return of(this.rideStatusTypes) }
    //   return this.proxies.rideStatusTypes().pipe(
    //     map(res => this.buildList<RideStatusType>(res, 'name', 'rideStatusId')),
    //     tap(res => this.rideStatusTypes = res)
    //   )
    // }
    return of(STATUSES);
  }
}

export interface Value<T> {
  list: T[],
  maps: {
    [key: string]: { [key: string]: T }
  },
  getById: (id: string | number) => T,
  getByName: (name: string) => T
}
