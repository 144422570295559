import { AddressModel } from '../../shared/services/nswag/service-proxies';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressParserService {
  address: AddressModel;

  constructor() { }

  parseAddressFromPlace(place: google.maps.places.PlaceResult): AddressModel {
    const byType = (place.address_components || []).reduce(function (
      acc,
      data,
    ) {
      data.types.forEach(function (type) {
        acc[type] = data;
      });
      return acc;
    },
      {});

    const result = {
      name: place.name,
      streetNumber: getAddressComponent('street_number'),
      streetName: getAddressComponent('route'),
      city:
        getAddressComponent('locality') ||
        getAddressComponent('sublocality') ||
        getAddressComponent('sublocality_level_1') ||
        getAddressComponent('neighborhood') ||
        getAddressComponent('administrative_area_level_3') ||
        getAddressComponent('administrative_area_level_2'),
      county: getAddressComponent('administrative_area_level_2'),
      stateShort: getAddressComponent('administrative_area_level_1', true),
      stateLong: getAddressComponent('administrative_area_level_1'),
      countryShort: getAddressComponent('country', true),
      countryLong: getAddressComponent('country'),
      zip: getAddressComponent('postal_code'),
    };

    return {
      name: result.name,
      address1: getAddressLine1(result),
      address2: null,
      city: result.city,
      state: result.stateShort,
      zip: result.zip,
      county: result.county,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      phone: undefined,
      isFavorite: undefined,
      addressId: undefined,
    } as AddressModel;

    function getAddressLine1(place_res) {
      const line1 = place_res.streetNumber
        ? `${place_res.streetNumber} ${place_res.streetName} `
        : place.address_components[0].short_name;
      return line1;
    }

    function getAddressComponent(key, short?) {
      if (!(key in byType)) {
        return '';
      }
      return short ? byType[key].short_name : byType[key].long_name;
    }
  }
}
