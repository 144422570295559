<form class="form" [formGroup]="form" *ngIf="loaded && !crashed">
  <h2 *ngIf="showTitle">Payment Information</h2>
  <fieldset class="card-information">
    <legend class="title">Card Information</legend>

    <label for="card-number" class="required">Card</label>
    <div id="card-number" class="input" [ngClass]="{'invalid': !validNumber}"></div>

    <label for="card-cvv" class="required">CVV</label>
    <div id="card-cvv" class="input" [ngClass]="{'invalid': !validCvv}"></div>

    <fieldset class="expiration-date">
      <legend>Expiration Date</legend>
      <label>
        Month
        <input placeholder="01" type="text" inputmode="numeric" formControlName="month" [required]="required('month')"
          maxlength="2">
        <mat-error *ngIf="getError('month', 'pattern')">Please provide a valid month value</mat-error>
        <mat-error *ngIf="getError('month', 'required')">This field is required</mat-error>
      </label>
      <label>
        Year
        <input [placeholder]="minYear + 5" type="number" [min]="minYear" [max]="maxYear" formControlName="year"
          [required]="required('year')">
        <mat-error *ngIf="getError('year', 'min')">Expiration year cannot be in the past</mat-error>
        <mat-error *ngIf="getError('year', 'max')">Please provide a valid year</mat-error>
        <mat-error *ngIf="getError('year', 'required')">This field is required</mat-error>
      </label>
    </fieldset>
  </fieldset>

  <fieldset>
    <legend class="title">Card Holder Information</legend>
    <label>
      First Name
      <input autocomplete="given-name" type="text" formControlName="firstname" [required]="required('firstName')"
        maxlength="50">
      <mat-error *ngIf="getError('firstName', 'maxlength')">First name is too long</mat-error>
      <mat-error *ngIf="getError('firstName', 'required')">This field is required</mat-error>
    </label>

    <label>
      Last Name
      <input autocomplete="family-name" type="text" formControlName="lastname" [required]="required('lastName')"
        maxlength="50">
      <mat-error *ngIf="getError('lastName', 'maxlength')">Last name is too long</mat-error>
      <mat-error *ngIf="getError('lastName', 'required')">This field is required</mat-error>
    </label>

    <label>
      Phone Number
      <input autocomplete="tel" type="tel" formControlName="phone" [required]="required('phone')" minlength="10">
      <mat-error *ngIf="getError('tel', 'minlength')">Phone is too short</mat-error>
      <mat-error *ngIf="getError('lastName', 'required')">This field is required</mat-error>
    </label>

    <fieldset class="billing-address">
      <legend>Billing Address</legend>
      <label>
        Address Line 1
        <input autocomplete="address-line1" type="text" formControlName="address_1" [required]="required('address_1')"
          maxlength="255">
        <mat-error *ngIf="getError('address_1', 'maxlength')">Address Line 1 is too long</mat-error>
        <mat-error *ngIf="getError('address_1', 'required')">This field is required</mat-error>
      </label>
      <label>
        Address Line 2
        <input autocomplete="address-line2" type="text" formControlName="address_2" [required]="required('address_2')"
          maxlength="255">
        <mat-error *ngIf="getError('address_2', 'maxlength')">Address Line 2 is too long</mat-error>
        <mat-error *ngIf="getError('address_2', 'required')">This field is required</mat-error>
      </label>
      <label class="city">
        City
        <input autocomplete="address-level2" type="text" formControlName="address_city"
          [required]="required('address_city')" maxlength="255">
        <mat-error *ngIf="getError('address_city', 'maxlength')">City is too long</mat-error>
        <mat-error *ngIf="getError('address_city', 'required')">This field is required</mat-error>
      </label>
      <label class="state">
        State
        <mat-select autocomplete="address-level1" formControlName="address_state">
          <mat-option *ngFor="let state of states" [value]="state.code">{{ state.code }}</mat-option>
        </mat-select>
        <mat-error *ngIf="getError('address_state', 'minlength')">Phone is too short</mat-error>
        <mat-error *ngIf="getError('address_state', 'required')">This field is required</mat-error>
      </label>
      <label class="zip">
        Zip
        <input autocomplete="postal-code" type="text" formControlName="address_zip" [required]="required('address_zip')"
          maxlength="5" inputmode="numeric">
        <mat-error *ngIf="getError('address_zip', 'maxlength')">Postal Code is too long</mat-error>
        <mat-error *ngIf="getError('address_zip', 'required')">This field is required</mat-error>
        <mat-error *ngIf="getError('address_zip', 'pattern')">Invalid zip code</mat-error>
      </label>
    </fieldset>
  </fieldset>
</form>

<div class="crashed" *ngIf="crashed">
  <h2 class="error">Failed to load payment system</h2>
  <p>We apologize for the inconvenience</p>
  <img src="../../../../assets/img/crashed.svg" alt height="300">
  <div class="actions">
    <button type="button" (click)="reload()">
      <mat-icon>refresh</mat-icon>
      Reload Page
    </button>
  </div>
</div>
