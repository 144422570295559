import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {

  transform(value: string, ext?: string, noPhoneText: string = 'Phone Not Set'): string {
    if (!value) {
      return noPhoneText;
    }
    let newValue = value.replace(/\D/g, '');
    const match = value.length === 11 && value[0] === '1'
      ? newValue.match(/^1(?<areaCode>\d{3})(?<telPrefix>\d{3})(?<lineNum>\d{4})$/)
      : newValue.match(/^(?<areaCode>\d{3})(?<telPrefix>\d{3})(?<lineNum>\d{4})$/);
    if (match) {
      newValue = match.groups['areaCode'] === '800'
        ? '1 800' + match.groups['telPrefix'] + ' - ' + match.groups['lineNum']
        : '(' + match.groups['areaCode'] + ') ' + match.groups['telPrefix'] + ' - ' + match.groups['lineNum'];
    }
    if (ext) { newValue += 'x' + ext; }
    return newValue;
  }

}
