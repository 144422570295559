<ion-header>
  <ion-toolbar mode="md" color="light">
    <ion-buttons slot="start">
      <ion-button *ngIf="back && back.text !== 'back'" [routerLink]="back.path" [queryParams]="queryParams"
        (click)="cancel()">
        {{ back.text }}
      </ion-button>
      <ion-back-button *ngIf="back && !hideBackBtn && back.text === 'back'" defaultHref="{{ back.path }}"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <a [routerLink]="['/rides/future']">
        <img alt="Trip Master" class="header-logo" src="/assets/img/logo.svg" />
      </a>
    </ion-title>
  </ion-toolbar>
</ion-header>
