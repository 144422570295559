import {
  ServiceProxy,
  UserChangePasswordModel,
  UserLoginModel,
  TripPortalSettings,
  Coordinate,
} from 'src/app/shared/services/nswag/service-proxies';
import { map, switchMap, tap } from 'rxjs/operators';

import { ExtendedUserProfile } from '../../models/profile.type';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of, ReplaySubject } from 'rxjs';
import { TrackJS } from 'trackjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _currentUser: ExtendedUserProfile = null;
  portalSettings: TripPortalSettings;

  private readonly _currentUser$ = new ReplaySubject<ExtendedUserProfile | null>(1);
  public readonly currentUser$ = this._currentUser$.asObservable();

  constructor(
    private proxies: ServiceProxy,
  ) {
  }

  async init(): Promise<ExtendedUserProfile | void> {
    try {
      await firstValueFrom(this.proxies.loginCheckPOST());
      return this.getCurrentUser();
    } catch {
      return this.removeCurrentUser();
    }
  }

  /**
   * Saves an email address for future display. This allows the email address
   * to survive app reload.
   */
  setEmailAddress(emailAddress: string) {
    localStorage.setItem(environment.keys.email, emailAddress);
  }

  /**
   * Sends a login request to the API. As a side effect will update the email
   * address.
   * @returns the current user (after successful login)
   */
  login(credentials: UserLoginModel): Observable<ExtendedUserProfile> {
    this.setEmailAddress(credentials.email);
    return this.proxies.login(credentials).pipe(
      map((success) => {
        if (!success) { return null; }
        const user: ExtendedUserProfile = {
          token: success.token,
          ...success.userProfile,
        };
        return this.setCurrentUser(user);
      }),
      switchMap((user) => {
        if (user) {
          return this.getPortalSettings(credentials.email, user.isDelegateUser).pipe(
            map(() => user),
          );
        } else {
          return of(user);
        }
      }),
    );
  }



  verifyEmail({ code, userId, providerCode }: { code: string, userId: string, providerCode: string }): Observable<ExtendedUserProfile> {
    return this.proxies.emailVerification(userId, code, providerCode).pipe(
      map(success => {
        const user: ExtendedUserProfile = {
          token: success.token,
          ...success.userProfile,
        };
        return user;
      }),
    );
  }

  /**
   * Sends a registration request to the API which both accepts an invitation,
   * sets the password, and completes login.
   */
  setPassword(password: string, isProvider: boolean): Observable<string> {
    return isProvider
      ? this.proxies.delegateSetPassword(password, password)
      : this.proxies.setPassword(password, password);
  }

  /**
  * Sets the token for the invitated delegate
  * this is just used to send an authenticated request when creating the password
  */
  setDelegateInvitationToken(token: string) {
    const user: ExtendedUserProfile = {
      token: token,
      firstName: '',
      middleInitial: '',
      lastName: '',
      genderId: 0,
      genderDescription: '',
      dateOfBirth: '',
      phoneNumber: '',
      address: undefined,
      rideRequirements: [],
      prefersTextMessage: false,
      emailAddress: '',
      isDelegateUser: true,
      portalUserId: '',
      comments: '',
      transportationProvider: undefined,
      vendorTransportationProviders: [],
      transportationProviders: [],
    };
    this.setCurrentUser(user);
  }

  /**
   * Removes our record of the current user and sends a logout instruction to
   * the API.
   */
  async logout(): Promise<boolean> {
    const logout = await firstValueFrom(this.proxies.logout()).catch(err => {
      console.error(err);
      return null;
    });
    this.removeCurrentUser();
    return logout;
  }

  /**
   * Requests the current user from the API to validate that they are still
   * authenticated. As a side effect will update our record of the current
   * user.
   * @returns the current user
   * @see #getCurrentUser()
   */
  // validateCurrentUser(): Observable<boolean> {
  //   return this.authSvc.GetCurrentUser().pipe(
  //     map((user) => {
  //       debugger;
  //       return true;
  //     })
  //   );
  // }

  /**
   * Returns the latest stored email address (from the currently authenticated
   * user, when available), typically for display purposes to the user.
   */
  getEmailAddress(): string {
    if (this.getCurrentUser() && this.getCurrentUser().emailAddress) {
      return this.getCurrentUser().emailAddress;
    }
    return localStorage.getItem(environment.keys.email);
  }

  /**
   * Returns the current user based upon the last update form the API. This
   * differs from #validateCurrentUser() in that it does not hit the API, which
   * is suitable for most purposes other than route guards. This current user
   * is persistent, which means that client authentication survives app reload.
   */
  getCurrentUser(): ExtendedUserProfile {
    if (!this._currentUser) {
      const storedJSON = sessionStorage.getItem(environment.keys.currentUser);
      if (storedJSON) {
        this._currentUser = JSON.parse(storedJSON);
        this._currentUser$.next(this._currentUser);
      }
    }
    return this._currentUser;
  }
  /**
   * Saves a local and persistent copy of the current user.
   * @returns the current user that was just set
   */
  setCurrentUser(
    currentUser: ExtendedUserProfile,
  ): ExtendedUserProfile {
    this._currentUser = currentUser;
    this._currentUser$.next(currentUser);
    sessionStorage.setItem(
      environment.keys.currentUser,
      JSON.stringify(currentUser),
    );
    TrackJS.configure({ userId: String(currentUser.emailAddress) });
    return currentUser;
  }

  /**
   * Clears our record of the current user as required for logout.
   */
  public removeCurrentUser() {
    this._currentUser = null;
    this.portalSettings = null;
    this._currentUser$.next(null);
    sessionStorage.removeItem(environment.keys.currentUser);
    sessionStorage.removeItem(environment.keys.portalSettings);
    sessionStorage.removeItem(environment.keys.providerAssigned);
    TrackJS.configure({ userId: '' });
  }

  /**
   * find a provider based on pick up and drop off location
   * if a provider exist get the portal setting for that provider
   */
  public getProviderPortalSettingsByLocation(coordinates: Coordinate[] = []): Observable<TripPortalSettings> {
    return this.proxies.location(coordinates).pipe(
      tap((settings) => {
        sessionStorage.removeItem(environment.keys.portalSettings);
        if (settings) {
          sessionStorage.setItem(environment.keys.portalSettings, JSON.stringify(settings));
        }
      }),
    );
  }

  /**
   * retrieves portal settings from api
   */
  public getPortalSettings(email: string, isDelegateUser: boolean): Observable<TripPortalSettings> {
    return (isDelegateUser ? this.proxies.delegatePortalSettings(email) : this.proxies.portalSettings(email))
      .pipe(
        tap((settings) => {
          this.setLocalPortalSettings(settings);
        }));
  }

  /**
   * retrieves local portal settings from session storage
   */
  public getLocalPortalSettings(): TripPortalSettings | null {
    const portalSettingsJSON = sessionStorage.getItem(environment.keys.portalSettings);
    this.portalSettings = portalSettingsJSON ? JSON.parse(portalSettingsJSON) : null;
    return this.portalSettings;
  }

  public setLocalPortalSettings(portalSettings: TripPortalSettings): TripPortalSettings | null {
    if (portalSettings) {
      sessionStorage.setItem(environment.keys.providerAssigned, 'true');
      sessionStorage.setItem(environment.keys.portalSettings, JSON.stringify(portalSettings));
    } else {
      sessionStorage.setItem(environment.keys.providerAssigned, 'false');
      sessionStorage.removeItem(environment.keys.portalSettings);
    }
    return portalSettings;
  }

  public getIsProviderAssigned(): boolean {
    const sessionIsProviderAssigned = sessionStorage.getItem(environment.keys.providerAssigned);
    const isProviderAssigned = sessionIsProviderAssigned === 'true' ? true : false;
    return isProviderAssigned;
  }

  public forgotPasswordConfirm(userId: string, code: string): Observable<boolean> {
    return this.proxies.forgotPasswordConfirm(userId, code);
  }
  public forgotPasswordChange(userId: string, code: string, changePasswordModel: UserChangePasswordModel): Observable<boolean> {
    return this.proxies.forgotPasswordChange(userId, code, changePasswordModel);
  }
}
